import React, { useState } from 'react';
import { useUsers } from '../../hooks/useUsers';
import UserCard from './UserCard';

const UserListSection: React.FC = () => {
    const { users, error, loading } = useUsers();
    const [searchTerm, setSearchTerm] = useState('');

    // Remove duplicates based on 'uuid'
    const uniqueUsers = Array.from(
        new Map(users.map((user) => [user.uuid, user])).values()
    );

    // Filter users
    const filteredUsers = uniqueUsers.filter((user) => {
        const search = searchTerm.toLowerCase();
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        const email = user.email.toLowerCase();
        const license = user.pilot_license_number?.toLowerCase() ?? '';

        return (
            fullName.includes(search) ||
            email.includes(search) ||
            license.includes(search)
        );
    });

    if (loading) {
        return (
            <div className="flex justify-center items-center py-10">
                <span className="text-gray-500">Loading users...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col justify-center items-center py-10">
                <span className="text-red-500 mb-4">{error}</span>
            </div>
        );
    }

    return (
        <main>
            <div className="px-4 sm:px-6 lg:px-8">
                {/* Header */}
                <div className="mt-8 pb-6 px-4 sm:px-6 lg:px-8">
                    <div className="lg:flex pb-6 lg:items-center lg:justify-between">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                Users
                            </h2>
                        </div>
                        {/* Search Bar */}
                        <div className="mt-4 lg:mt-0">
                            <input
                                type="text"
                                placeholder="Search users..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {/* User List */}
                    <ul
                        role="list"
                        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                    >
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user) => (
                                <UserCard key={user.uuid} user={user} />
                            ))
                        ) : (
                            <div className="col-span-full text-center text-gray-500">
                                No users found.
                            </div>
                        )}
                    </ul>
                </div>
            </div>
        </main>
    );
};

export default UserListSection;
