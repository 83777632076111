import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import apiClient from "../../tools/AxiosInterceptor";
import {XCircleIcon} from '@heroicons/react/20/solid'

interface LoginResponse {
    token: string;
    role: string;
    uuid: string;
    email: string;
}

const LoginScreen = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the location object
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Get the 'from' state, default to "/dashboard/flights" if not present
    const from = (location.state as { from?: Location })?.from?.pathname || "/dashboard/flights";
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);

        const formData = new FormData(e.currentTarget);
        const data = {
            login: formData.get("login") as string,
            password: formData.get("password") as string
        };

        try {
            const response = await apiClient.post<LoginResponse>("/login", data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            } as any);

            if (response.status === 200 && response.data) {
                sessionStorage.setItem('token', response.data.token);       // Store the token
                sessionStorage.setItem('user_role', response.data.role);    // Store the user's role
                sessionStorage.setItem('user_uuid', response.data.uuid);    // Store the user's UUID
                sessionStorage.setItem('email', response.data.email);       // Store the user's email
                navigate(from);
            } else {

                console.log(response.data);
            }
        } catch (error) {
            setErrorMessage("Invalid login or password.");
            console.error("Error during the login process", error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Login
                        </label>
                        <div className="mt-2">
                            <input
                                id="login"
                                name="login"
                                type="text"
                                autoComplete="login"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <div className="rounded-md bg-red-50 p-2">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default LoginScreen;