import React, {Fragment} from 'react';
import {PlusCircleIcon, BellAlertIcon, ChevronDownIcon} from '@heroicons/react/20/solid';
import AircraftPartStatuses from "./AircraftPartStatuses";
import {Menu, Transition} from '@headlessui/react'
import {AircraftDetailsProps} from '../../../types';




function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const AircraftDetails: React.FC<AircraftDetailsProps> = ({
                                                             aircraft,
                                                             onAddComponent,
                                                             onEditComponent,
                                                             onIssuesClick
                                                         }) => {

    return (
        <div>
            <div className="py-10">
                <div className="px-4 pb-6 sm:px-6 lg:px-8">
                    <div className="lg:flex lg:items-center lg:justify-between">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                {aircraft.registration}
                            </h2>
                            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                                <div className="mt-2 flex items-center text-m text-gray-500">
                                    {aircraft.manufacturer} {aircraft.model}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">


        <span className="hidden sm:block">
            {sessionStorage.getItem('user_role') === 'admin' && (
                <button
                    type="button"
                    onClick={onAddComponent}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    <PlusCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>

                    Add Component
                </button>
            )}
        </span>


                            <span className="sm:ml-3">
          <button
              type="button"
              onClick={onIssuesClick}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
              <BellAlertIcon className="-ml-0.5 mr-1.5 h-5 w-5 " aria-hidden="true"/>
            Issues
          </button>
        </span>

                            <Menu as="div" className="relative ml-3 sm:hidden">
                                <Menu.Button
                                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                                    More
                                    <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    href="#"
                                                    onClick={onAddComponent}
                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                >
                                                    Add Component
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                    <AircraftPartStatuses
                        aircraft={aircraft}
                        onEditComponent={onEditComponent}
                    />
                </div>
            </div>
        </div>
    );
};

export default AircraftDetails;