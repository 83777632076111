import {Disclosure} from '@headlessui/react'
import {MinusSmallIcon, PlusSmallIcon} from '@heroicons/react/24/outline'
import {useTranslation} from "react-i18next";

const faqs = [
    {
        question: "faqQuestion1",
        answer: "faqAnswer1"
    },
    {
        question: "faqQuestion2",
        answer: "faqAnswer2"
    },
    {
        question: "faqQuestion3",
        answer: "faqAnswer3"
    },
    {
        question: "faqQuestion4",
        answer: "faqAnswer4"
    },
    {
        question: "faqQuestion5",
        answer: "faqAnswer5"
    },
    {
        question: "faqQuestion6",
        answer: "faqAnswer6"
    },
    {
        question: "faqQuestion7",
        answer: "faqAnswer7"
    },
    {
        question: "faqQuestion8",
        answer: "faqAnswer8"
    }
];

export default function FAQSection() {
    const { t } = useTranslation();
    return (
        <div id="faq" className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">{t('faqTitle')}</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({open}) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button
                                                className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span
                                                    className="text-base font-semibold leading-7">{t(faq.question)}</span>
                                                <span className="ml-6 flex h-7 items-center">
                          {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true"/>
                          ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true"/>
                          )}
                        </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{t(faq.answer)}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
