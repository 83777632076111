// components/UserCard.tsx
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import md5 from "md5";
import {User} from "../../types";

interface UserCardProps {
    user: User;
}

const getGravatarUrl = (email: string) => {
    const hash = md5(email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?d=mp`;
};

const UserCard: React.FC<UserCardProps> = ({ user }) => (
    <li
        key={user.email}
        className="bg-white col-span-1 divide-y divide-gray-200 rounded-lg shadow"
    >
        <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                        {user.first_name} {user.last_name}
                    </h3>
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {user.role}
          </span>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                    {user.pilot_license_number}
                </p>
            </div>
            <img
                className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                src={getGravatarUrl(user.email)}
                alt={`${user.first_name} ${user.last_name}`}
            />
        </div>
        <div>
            <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                    <a
                        href={`mailto:${user.email}`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                        <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        Email
                    </a>
                </div>
                {user.phoneNumber && (
                    <div className="-ml-px flex w-0 flex-1">
                        <a
                            href={`tel:${user.phoneNumber}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                        >
                            <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            Call
                        </a>
                    </div>
                )}
            </div>
        </div>
    </li>
);

export default UserCard;