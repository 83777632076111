import React, {useState, useEffect} from 'react';
import {v4 as uuidv4} from "uuid";
import {Switch} from '@headlessui/react';
import {MaintenanceSchedule, MaintenanceHistoryRecord} from "../../../../types";
import {PhotoIcon, UserCircleIcon} from '@heroicons/react/24/solid';

interface MaintenanceScheduleFormProps {
    onSubmit: (schedule: MaintenanceSchedule) => void;
    onCancel: () => void;
    maintenanceSchedule: MaintenanceSchedule | null;
}

const MaintenanceScheduleForm: React.FC<MaintenanceScheduleFormProps> = ({
                                                                             onSubmit,
                                                                             onCancel,
                                                                             maintenanceSchedule,
                                                                         }) => {
    const initialFormState: MaintenanceSchedule = {
        uuid: uuidv4(),
        description: '',
        cycle: [{type: '', threshold: ''}],
        lastPerformed: {
            date: '',
            expiryDate: '',
            hoursAtMaintenance: 0,
            confirmationDocument: '',
            remarks: ''
        },
        history: []
    };

    const [schedule, setSchedule] = useState<MaintenanceSchedule>(initialFormState);

    useEffect(() => {
        if (maintenanceSchedule) {
            setSchedule({
                ...maintenanceSchedule,
                cycle: maintenanceSchedule.cycle.length > 0 ? maintenanceSchedule.cycle : [{type: '', threshold: ''}],
                history: maintenanceSchedule.history.length > 0 ? maintenanceSchedule.history : []
            });
        }
    }, [maintenanceSchedule]);

    // Handle changes for simple input fields
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setSchedule(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle changes for nested lastPerformed fields
    const handleLastPerformedChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        let parsedValue: string | number | null = value;

        if (name === 'hoursAtMaintenance') {
            parsedValue = value !== '' ? Number(value) : 0;
            if (isNaN(parsedValue as number)) {
                parsedValue = 0; // Default to 0 or handle as needed
            }
        }

        setSchedule(prev => ({
            ...prev,
            lastPerformed: {
                ...prev.lastPerformed,
                [name]: parsedValue
            }
        }));
    };


    // Handle changes for cycle array
    const handleCycleChange = (
        index: number,
        field: string,
        value: string | number
    ) => {
        const updatedCycle = [...schedule.cycle];
        let parsedValue: string | number = value;

        // Assuming 'threshold' should be a number
        if (field === 'threshold') {
            parsedValue = value !== '' ? Number(value) : 0;
            if (isNaN(parsedValue as number)) {
                parsedValue = 0; // Default to 0 or handle as needed
            }
        }

        updatedCycle[index] = { ...updatedCycle[index], [field]: parsedValue };
        setSchedule(prev => ({
            ...prev,
            cycle: updatedCycle
        }));
    };


    // Add a new cycle item
    const addCycle = () => {
        setSchedule(prev => ({
            ...prev,
            cycle: [...prev.cycle, {type: '', threshold: ''}]
        }));
    };

    // Remove a cycle item
    const removeCycle = (index: number) => {
        const updatedCycle = [...schedule.cycle];
        updatedCycle.splice(index, 1);
        setSchedule(prev => ({
            ...prev,
            cycle: updatedCycle.length > 0 ? updatedCycle : [{type: '', threshold: ''}]
        }));
    };

    // Handle changes for history array
    const handleHistoryChange = (
        index: number,
        field: keyof MaintenanceHistoryRecord,
        value: string | number | null
    ) => {
        const updatedHistory = [...schedule.history];

        let parsedValue: string | number | null = value;

        // Specifically handle numeric fields
        if (field === 'hoursAtMaintenance') {
            parsedValue = value !== null && value !== '' ? Number(value) : 0;
            if (isNaN(parsedValue as number)) {
                parsedValue = 0; // Default to 0 or handle as needed
            }
        }

        updatedHistory[index] = { ...updatedHistory[index], [field]: parsedValue };
        setSchedule(prev => ({
            ...prev,
            history: updatedHistory
        }));
    };

    // Add a new history record
    const addHistory = () => {
        setSchedule(prev => ({
            ...prev,
            history: [...prev.history, {
                date: '',
                expiryDate: null,
                hoursAtMaintenance: 0,
                confirmationDocument: '',
                remarks: ''
            }]
        }));
    };

    // Remove a history record
    const removeHistory = (index: number) => {
        const updatedHistory = [...schedule.history];
        updatedHistory.splice(index, 1);
        setSchedule(prev => ({
            ...prev,
            history: updatedHistory
        }));
    };

    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Basic validation
        if (!schedule.description.trim()) {
            alert("Description is required.");
            return;
        }

        // Ensure all cycle items have type and threshold
        for (let i = 0; i < schedule.cycle.length; i++) {
            const cycle = schedule.cycle[i];
            if (!cycle.type.trim() || cycle.threshold === '') {
                alert(`Cycle item ${i + 1} requires both type and threshold.`);
                return;
            }
        }

        onSubmit(schedule);

        // Reset form if adding new schedule
        if (!maintenanceSchedule) {
            setSchedule(initialFormState);
        }
    };

    return (
        <div>
            <main>
                <div className="mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="space-y-12 divide-y divide-gray-900/10">
                        <form onSubmit={handleSubmit}>
                            {/* General Information Section */}
                            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                                <div className="px-4 sm:px-0">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Maintenance
                                        Schedule</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Manage the maintenance schedules for your components.
                                    </p>
                                </div>

                                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                                    <div className="px-4 py-4 sm:p-8">
                                        <div className="grid max-w-2xl py-4 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            {/* Description */}
                                            <div className="sm:col-span-6">
                                                <label htmlFor="description"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Description
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="description"
                                                        name="description"
                                                        type="text"
                                                        value={schedule.description}
                                                        onChange={handleChange}
                                                        required
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Maintenance Description"
                                                    />
                                                </div>
                                            </div>

                                            {/* Cycle */}
                                            <div className="sm:col-span-6">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Cycle
                                                </label>
                                                {schedule.cycle.map((cycleItem, index) => (
                                                    <div key={index} className="mt-2 flex items-center gap-x-4">
                                                        <div className="flex-1">
                                                            <select
                                                                id={`cycleType-${index}`}
                                                                name={`cycleType-${index}`}
                                                                value={cycleItem.type}
                                                                onChange={(e) => handleCycleChange(index, 'type', e.target.value)}
                                                                required
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset
                                                                     focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            >
                                                                <option value="">Select Type</option>
                                                                <option value="Hourly">Hourly</option>
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Special">Special</option>
                                                            </select>
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                type="number"
                                                                id={`cycleThreshold-${index}`}
                                                                name={`cycleThreshold-${index}`}
                                                                value={cycleItem.threshold}
                                                                onChange={(e) => handleCycleChange(index, 'threshold', e.target.value)}
                                                                required
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                                     focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                placeholder="Threshold"
                                                            />
                                                        </div>
                                                        {schedule.cycle.length > 1 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => removeCycle(index)}
                                                                className="text-red-600 hover:text-red-800"
                                                            >
                                                                &times;
                                                            </button>
                                                        )}
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    onClick={addCycle}
                                                    className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
                                                          hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                                                          focus-visible:outline-green-600"
                                                >
                                                    Add Cycle
                                                </button>
                                            </div>

                                            {/* Last Performed Date */}
                                            <div className="sm:col-span-3">
                                                <label htmlFor="date"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Last Performed Date
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="date"
                                                        name="date"
                                                        type="date"
                                                        value={schedule.lastPerformed.date}
                                                        onChange={handleLastPerformedChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>

                                            {/* Expiry Date */}
                                            <div className="sm:col-span-3">
                                                <label htmlFor="expiryDate"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Expiry Date
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="expiryDate"
                                                        name="expiryDate"
                                                        type="date"
                                                        value={schedule.lastPerformed.expiryDate || ''}
                                                        onChange={handleLastPerformedChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>

                                            {/* Hours at Maintenance */}
                                            <div className="sm:col-span-3">
                                                <label htmlFor="hoursAtMaintenance"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Hours at Maintenance
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="hoursAtMaintenance"
                                                        name="hoursAtMaintenance"
                                                        type="number"
                                                        value={schedule.lastPerformed.hoursAtMaintenance}
                                                        onChange={handleLastPerformedChange}
                                                        required
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="0"
                                                    />
                                                </div>
                                            </div>

                                            {/* Confirmation Document */}
                                            <div className="sm:col-span-3">
                                                <label htmlFor="confirmationDocument"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Confirmation Document
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="confirmationDocument"
                                                        name="confirmationDocument"
                                                        type="text"
                                                        value={schedule.lastPerformed.confirmationDocument}
                                                        onChange={handleLastPerformedChange}
                                                        required
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Document Reference"
                                                    />
                                                </div>
                                            </div>

                                            {/* Remarks */}
                                            <div className="sm:col-span-6">
                                                <label htmlFor="remarks"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Remarks
                                                </label>
                                                <div className="mt-2">
                                                  <textarea
                                                      id="remarks"
                                                      name="remarks"
                                                      rows={3}
                                                      value={schedule.lastPerformed.remarks}
                                                      onChange={handleLastPerformedChange}
                                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                      placeholder="Additional remarks..."
                                                  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* History Section */}

                            <div className="mt-8 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                                <div className="px-4 sm:px-0">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">History</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Add or edit maintenance history records.
                                    </p>
                                </div>

                                <div
                                    className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                                    <div className="px-4 py-4 sm:p-8">
                                        {schedule.history.map((historyItem, index) => (
                                            <div key={index}
                                                 className="grid max-w-2xl py-4 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                {/* Date */}
                                                <div className="sm:col-span-2">
                                                    <label htmlFor={`historyDate-${index}`}
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Date
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            id={`historyDate-${index}`}
                                                            name={`historyDate-${index}`}
                                                            value={historyItem.date}
                                                            onChange={(e) => handleHistoryChange(index, 'date', e.target.value)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                                     focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Expiry Date */}
                                                <div className="sm:col-span-2">
                                                    <label htmlFor={`historyExpiryDate-${index}`}
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Expiry Date
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            id={`historyExpiryDate-${index}`}
                                                            name={`historyExpiryDate-${index}`}
                                                            value={historyItem.expiryDate || ''}
                                                            onChange={(e) => handleHistoryChange(index, 'expiryDate', e.target.value)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                                     focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Hours at Maintenance */}
                                                <div className="sm:col-span-2">
                                                    <label htmlFor={`historyHoursAtMaintenance-${index}`}
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Hours at Maintenance
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="number"
                                                            id={`historyHoursAtMaintenance-${index}`}
                                                            name={`historyHoursAtMaintenance-${index}`}
                                                            value={historyItem.hoursAtMaintenance}
                                                            onChange={(e) => handleHistoryChange(index, 'hoursAtMaintenance', e.target.value)}
                                                            required
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                                     focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="0"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Confirmation Document */}
                                                <div className="sm:col-span-2">
                                                    <label htmlFor={`historyConfirmationDocument-${index}`}
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Confirmation Document
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            id={`historyConfirmationDocument-${index}`}
                                                            name={`historyConfirmationDocument-${index}`}
                                                            value={historyItem.confirmationDocument}
                                                            onChange={(e) => handleHistoryChange(index, 'confirmationDocument', e.target.value)}
                                                            required
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                                     focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="Document Reference"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Remarks */}
                                                <div className="sm:col-span-6">
                                                    <label htmlFor={`historyRemarks-${index}`}
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Remarks
                                                    </label>
                                                    <div className="mt-2">
                                                          <textarea
                                                              id={`historyRemarks-${index}`}
                                                              name={`historyRemarks-${index}`}
                                                              rows={3}
                                                              value={historyItem.remarks}
                                                              onChange={(e) => handleHistoryChange(index, 'remarks', e.target.value)}
                                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                                                                     ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                                                     focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                              placeholder="Additional remarks..."
                                                          />
                                                    </div>
                                                </div>

                                                {/* Remove History Button */}
                                                {schedule.history.length > 1 && (
                                                    <div className="sm:col-span-6">
                                                        <button
                                                            type="button"
                                                            onClick={() => removeHistory(index)}
                                                            className="mt-2 inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
                                                                     hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                        >
                                                            Remove History
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}

                                        <button
                                            type="button"
                                            onClick={addHistory}
                                            className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
                                                     hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        >
                                            Add History
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Form Actions */}
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button
                                    type="button"
                                    onClick={onCancel}
                                    className="text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
                                             hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                             focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MaintenanceScheduleForm;
