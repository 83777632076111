import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardNav from "./DashboardNav";
import UserListSection from "./UserListSection";
import FlightManagementSection from "./FlightManagementSection";
import FuelManagementSection from "./FuelComponents/FuelManagementSection";
import AircraftManagementContainer from "./AircraftComponents/AircraftManagementContainer";
import UserProfileSection from "./UserProfileSection";
import AddFlightSection from "./AddFlightSection";
import EditFlightWrapper from "./EditFlightWrapper";

const DashboardScreen: React.FC = () => {
    return (
        <div className="min-h-full">
            <DashboardNav />
            <Routes>
                <Route path="flights" element={<FlightManagementSection />} />
                <Route path="flights/add" element={<AddFlightSection />} />
                <Route path="flights/edit/:uuid" element={<EditFlightWrapper />} />
                <Route path="fuel" element={<FuelManagementSection />} />
                <Route path="aircraft" element={<AircraftManagementContainer />} />
                <Route path="team" element={<UserListSection />} />
                <Route path="userprofile" element={<UserProfileSection />} />
            </Routes>
        </div>
    );
};

export default DashboardScreen;
