import React, {Fragment, useMemo} from "react";
import {Menu, Transition} from '@headlessui/react'
import {EllipsisHorizontalIcon} from '@heroicons/react/20/solid'
import {Aircraft, ComponentStatus} from "../../../types";

interface AircraftComponentStatusesProps {
    aircraft: Aircraft,
    onEditComponent: (componentUuid: string) => void;
}
const componentStatuses: Record<ComponentStatus, string> = {
    OK: 'text-green-700 bg-green-50 ring-green-600/20',
    Warning: 'text-yellow-600 bg-yellow-50 ring-yellow-500/10',
    Alert: 'text-red-700 bg-red-50 ring-red-600/10',
};
type StatusPriority = {
    [key in ComponentStatus]: number;
};
const statusPriorities = {
    Alert: 1,
    Warning: 2,
    OK: 3,
};
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const AircraftPartStatuses: React.FC<AircraftComponentStatusesProps> = ({aircraft, onEditComponent}) => {
    const sortedAircraftComponents = useMemo(() => {
        return (aircraft.components || []).map(component => {
            let sortKey = statusPriorities.OK; // Default sort key

            if (component.maintenanceDetails) {
                const { overallHoursStatus, overallCalendarStatus } = component.maintenanceDetails;
                const statuses: (ComponentStatus | undefined)[] = [overallHoursStatus, overallCalendarStatus];
                const mostUrgentStatus = statuses.reduce<ComponentStatus>((prev, current) => {
                    const prevPriority = statusPriorities[prev] ?? statusPriorities.OK;
                    const currentPriority = statusPriorities[current ?? "OK"] ?? statusPriorities.OK;
                    return prevPriority < currentPriority ? prev : (current ?? "OK");
                }, "OK");
                sortKey = statusPriorities[mostUrgentStatus];
            }

            return { ...component, sortKey };
        }).sort((a, b) => a.sortKey - b.sortKey);
    }, [aircraft.components]);

    return (
        <div className="py-8">
            <ul role="list" className="grid grid-cols-1 py-8 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">

                {sortedAircraftComponents.map((component) => {
                    // Call the helper function to get maintenance details
                    return (
                        <li key={component.uuid} className="overflow-hidden rounded-xl border border-gray-200">
                            <div className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-3">
                                <div className="text-sm font-medium leading-4 text-gray-900">{component.title}</div>
                                <div className="text-xs leading-4 text-gray-900">{component.partNumber}</div>
                                <div
                                    className="text-xs leading-4 text-gray-500">{component.serialNumber !== 'N/A' ? `S/N: ${component.serialNumber}` : ''}</div>
                                <Menu as="div" className="relative ml-auto">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Open options</span>
                                        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                {({active}) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => onEditComponent(component.uuid)}
                                                        className={classNames(
                                                            active ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        View<span className="sr-only">, {component.title}</span>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({active}) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => onEditComponent(component.uuid)}
                                                        className={classNames(
                                                            active ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Edit<span className="sr-only">, {component.title}</span>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-xs leading-6">
                                {component.maintenanceDetails?.componentHourlyServiced && (
                                    <>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Current time</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div
                                                    className=" text-gray-900">{component.currentHours} </div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Next service / Remaining</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div
                                                    className="text-gray-900">{component.maintenanceDetails.nextServiceHours}</div>
                                                <div
                                                    className={classNames(
                                                        componentStatuses[component.maintenanceDetails.overallHoursStatus ?? "OK"],
                                                        'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                    )}
                                                >
                                                    {component.maintenanceDetails.minHoursDifference}
                                                </div>
                                            </dd>
                                        </div>
                                    </>
                                )}

                                {component.maintenanceDetails?.componentCalendarServiced && (
                                    <>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Next service date</dt>
                                            <dd className="text-gray-700">
                                                {component.maintenanceDetails.nextServiceDate
                                                    ? component.maintenanceDetails.nextServiceDate.toLocaleDateString('pl-PL')
                                                    : 'N/A'}
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Remaining months</dt>
                                            <dd className={classNames(
                                                componentStatuses[component.maintenanceDetails.overallCalendarStatus ?? "OK"],
                                                'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                            )}>
                                                {component.maintenanceDetails.minMonthsDifference}
                                            </dd>
                                        </div>
                                    </>)}

                                {!component.maintenanceDetails?.componentCalendarServiced && !component.maintenanceDetails?.componentHourlyServiced && (
                                    <>
                                        <div className="flex justify-i gap-x-4 py-1 ">
                                            <dt className="text-gray-900 font-medium">No service schedule defined</dt>
                                        </div>
                                        <div className="flex justify-i gap-x-4 py-1 ">
                                            <dt className="text-gray-500 ">Define the schedules to display the
                                                statuses.
                                            </dt>
                                        </div>
                                    </>)}
                            </dl>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}

export default AircraftPartStatuses