import React, {Fragment, useState, useEffect} from "react";
import {Aircraft, InstalledComponent} from "../../../../types";
import {Menu, Transition} from '@headlessui/react';
import {EllipsisHorizontalIcon, PlusCircleIcon, CheckCircleIcon} from '@heroicons/react/20/solid';
import apiClient from "../../../../tools/AxiosInterceptor";
import { v4 as uuidv4 } from 'uuid';

interface AircraftIssueProps {
    aircraft: Aircraft;
}

interface Issue {
    "uuid": string,
    "cycleType"?: string,
    "threshold"?: number,
    "nextDueDate"?: string,
    "lastPerformedDate"?: string,
    "issue": string,
    "details"?: string,
    "priority": 'Critical' | 'High' | 'Medium' | 'Low' | 'Unknown',
    "status": string,
    "nextDueHours"?: number,
    "currentHours"?: number,
    "lastDoneHours"?: number,
    "actionNeeded"?: string,
    "estimatedLaborCost"?: string,
    "estimatedPartsCost"?: string,
    "partsNeeded"?: string,
}

interface Comment {
    dateTime: string;
    userUuid: string;
    userFirstLastName: string;
    text: string;
    editedDateTime?: string;  // Optional: Date and time when the comment was last edited
    attachments?: string[]; // Optional: URLs or identifiers of attachments
    commentType?: CommentType; // Optional: Type of the comment
    visibility?: CommentVisibility; // Optional: Who can see this comment
    reactions?: CommentReaction[]; // Optional: Reactions or acknowledgments to the comment
    relatedTo?: string[]; // Optional: References to specific maintenance steps or parts
}

type CommentType = 'General' | 'Technical' | 'Safety';
type CommentVisibility = 'Everyone' | 'Technicians' | 'Management';
type CommentReaction = 'Acknowledged' | 'Resolved' | 'Reviewed';

const statuses = {
    New: 'text-green-700 bg-green-50 ring-green-600/20',
    Review: 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
    Open: 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
    Closed: 'text-gray-600 bg-gray-50 ring-gray-600/20',
    Hold: 'text-gray-600 bg-gray-50 ring-gray-600/20',
    Updated: 'text-red-700 bg-red-50 ring-red-600/20',
};

const priorities = {
    Low: 'text-green-700 bg-green-50 ring-green-600/20',
    Medium: 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
    High: 'text-red-500 bg-red-50 ring-red-400/20',
    Critical: 'text-red-700 bg-red-50 ring-red-600/10',
};

type Priority = keyof typeof priorities;
type Status = keyof typeof statuses;
type EditIssueFormProps = {
    issue?: Issue;
    onSave: (issue: Issue) => void;
    onClose: () => void;
};

const priorityWeights = {
    "Critical": 1,
    "High": 2,
    "Medium": 3,
    "Low": 4,
    "Unknown": 5
};


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}


const AircraftIssueSection: React.FC<AircraftIssueProps> = ({aircraft}) => {
    const [error, setError] = useState<string | null>(null);
    const [issuesData, setIssuesData] = useState<Issue[]>([]);
    const [editingIssue, setEditingIssue] = useState<Issue | null>(null);
    const [addingNewIssue, setAddingNewIssue] = useState(false);
    const [notification, setNotification] = useState("");

    const sortIssuesByPriority = (issues: Issue[]) => {
        return issues.sort((a, b) => {
            return priorityWeights[a.priority] - priorityWeights[b.priority];
        });
    };

    useEffect(() => {
        // Explicitly declare timeoutId as a NodeJS.Timeout or number
        let timeoutId: NodeJS.Timeout | null = null;

        if (notification) {
            timeoutId = setTimeout(() => {
                setNotification("");
            }, 3000);
        }

        // Cleanup function to clear the timeout
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [notification]);


    useEffect(() => {
        // Assuming convertToIssues is a function that formats your data
        fetchIssues();
    }, []);

    const fetchIssues = async () => {
        try {
            const response = await apiClient.get('/v1/getAircraftIssues', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);
            const sortedIssues = sortIssuesByPriority(response.data);
            setIssuesData(sortedIssues);
        } catch (err: any) {
            setError(err.message || "Unable to fetch aircraft issues.");
        }
    };

    const handleSaveEditedIssue = async (updatedIssue: Issue) => {
        try {
            const response = await apiClient.put('/v1/updateAircraftIssue', updatedIssue, {
                headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
            } as any);
            fetchIssues(); // Refresh the issues list after update
            setNotification("Aircraft issue edited successfully."); // Set success message
            setEditingIssue(null); // Hide the form
        } catch (err: any) {
            setError(err.message || "Unable to update aircraft issue.");
        }
    };

    const handleAddNewIssue = async (newIssue: Issue) => {
        try {
            const response = await apiClient.post('/v1/addAircraftIssue', newIssue, {
                headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
            });
            fetchIssues(); // Refresh the issues list after adding
            setAddingNewIssue(false); // Hide the form
            setNotification("New aircraft issue added successfully."); // Set success message
        } catch (err: any) {
            setError(err.message || "Unable to add new aircraft issue.");
            setNotification(""); // Clear notification on error
        }
    };


    const handleDeleteIssue = async (issueId: string) => {
        if (window.confirm('Are you sure you want to delete this issue?')) {
            try {
                const response = await apiClient.delete(`/v1/deleteAircraftIssue/${issueId}`, {
                    headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
                } as any);
                fetchIssues(); // Refresh the issues list after deletion
                setNotification("Aircraft issue deleted successfully."); // Set success message
            } catch (err: any) {
                setError(err.message || "Unable to delete aircraft issue.");
            }
        }
    };

    const handleEdit = (issueId: string) => {
        const issueToEdit = issuesData.find(issue => issue.uuid === issueId);
        if (issueToEdit) {
            setEditingIssue(issueToEdit);
            // Additional logic to open a modal or navigate to the edit form
        }
    };

    const handleAddNewIssueClick = () => {
        setAddingNewIssue(true);
    };

    const EditIssueForm: React.FC<EditIssueFormProps> = ({issue, onSave, onClose}) => {

        const initialState: Issue = issue || {
            uuid: uuidv4(),
            issue: '',
            priority: 'Low', // TypeScript now understands this as 'Critical' | 'High' | 'Medium' | 'Low' | 'Unknown'
            cycleType: '',
            threshold: 0,
            nextDueDate: '',
            lastPerformedDate: '',
            details: '',
            status: 'New', // Make sure 'New' is a valid value for status in your Issue type
            nextDueHours: 0,
            currentHours: 0,
            lastDoneHours: 0,
            actionNeeded: '',
            estimatedLaborCost: '',
            estimatedPartsCost: '',
            partsNeeded: '',
        };
        const [issueToEdit, setIssueToEdit] = useState<Issue>(initialState);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            const {name, value} = e.target;
            setIssueToEdit(prevIssue => ({
                ...prevIssue,
                [name]: value
            }));
        };


        const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSave(issueToEdit); // Pass the updated issue object
        };

        const isEditMode = issue != null;

        return (
            <ul role="list" className="grid grid-cols-1 py-8 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
                <li key={issue ? issue.uuid : 'new-issue'} className="overflow-hidden rounded-xl border border-gray-200">
                    <div
                        className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-3">
                        <div
                            className="text-sm font-medium leading-4 text-gray-900">{isEditMode ? `Editing ${issue.issue}` : 'Add New Issue'}</div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-xs leading-6">
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-2">
                                    <label htmlFor="issue"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Issue
                                    </label>
                                    <div className="mt-2 ">
                                        <input
                                            type="text"
                                            name="issue"
                                            id="issue"
                                            value={issueToEdit.issue}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Enter issue description"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="details"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Issue Details
                                    </label>
                                    <div className="mt-2 ">
                                        <input
                                            type="text"
                                            name="details"
                                            id="details"
                                            value={issueToEdit.details}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Enter issue details"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="priority"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Priority
                                    </label>
                                    <select
                                        id="priority"
                                        name="priority"
                                        value={issueToEdit.priority}
                                        onChange={handleChange}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue="Low"
                                    >
                                        <option>Unknown</option>
                                        <option>Low</option>
                                        <option>Medium</option>
                                        <option>High</option>
                                        <option>Critical</option>
                                    </select>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="status"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Status
                                    </label>
                                    <select
                                        id="status"
                                        name="status"
                                        value={issueToEdit.status}
                                        onChange={handleChange}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue="New"
                                    >
                                        <option>New</option>
                                        <option>Review</option>
                                        <option>Open</option>
                                        <option>Closed</option>
                                        <option>Hold</option>
                                        <option>Updated</option>

                                    </select>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="actionNeeded"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Action Needed
                                    </label>
                                    <div className="mt-2 ">
                                        <input
                                            type="text"
                                            name="actionNeeded"
                                            id="actionNeeded"
                                            value={issueToEdit.actionNeeded}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Who needs to do what?"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="estimatedLaborCost"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Labor Cost Estimate (net, EUR)
                                    </label>
                                    <div className="mt-2 ">
                                        <input
                                            type="number"
                                            name="estimatedLaborCost"
                                            id="estimatedLaborCost"
                                            value={issueToEdit.estimatedLaborCost}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Price for labor"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="estimatedPartsCost"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Parts Cost Estimate (net, EUR)
                                    </label>
                                    <div className="mt-2 ">
                                        <input
                                            type="number"
                                            name="estimatedPartsCost"
                                            id="estimatedPartsCost"
                                            value={issueToEdit.estimatedPartsCost}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Price for parts"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="partsNeeded"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Needed parts P/N
                                    </label>
                                    <div className="mt-2 ">
                                        <input
                                            type="text"
                                            name="partsNeeded"
                                            id="partsNeeded"
                                            value={issueToEdit.partsNeeded}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Part numbers and quantity needed to resolve"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-end gap-x-6 gap-y-4 p-8">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="text-sm font-semibold leading-6 text-gray-900">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </dl>
                </li>
            </ul>
        )
            ;
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {notification && <div className="mt-4 rounded-md bg-green-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">{notification}</h3>
                    </div>
                </div>
            </div>}
            {editingIssue && (
                <EditIssueForm
                    issue={editingIssue}
                    onSave={handleSaveEditedIssue} // Pass handleSaveEditedIssue directly
                    onClose={() => setEditingIssue(null)}
                />
            )}
            {addingNewIssue && (
                <EditIssueForm
                    onSave={handleAddNewIssue}
                    onClose={() => setAddingNewIssue(false)}
                />
            )}
            <div className="mt-8 px-4 sm:px-6 lg:px-8">
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Issues
                        </h2>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">


                        {/*<span className="hidden sm:block">*/}
                        {/*  <button*/}
                        {/*      type="button"*/}
                        {/*      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
                        {/*  >*/}
                        {/*    <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                        {/*    Edit*/}
                        {/*  </button>*/}
                        {/*</span>*/}


                        <span className="sm:ml-3">
          <button
              type="button"
              onClick={handleAddNewIssueClick}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
            Add Issue
          </button>
        </span>
                    </div>
                </div>
                <div>
                    <ul role="list" className="grid grid-cols-1 py-8 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                        {issuesData && issuesData.map((issue) => {
                            return (
                                <li key={issue.uuid} className="overflow-hidden rounded-xl border border-gray-200">
                                    <div
                                        className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-3">
                                        <div
                                            className="text-sm font-medium leading-4 text-gray-900">{issue.issue}</div>
                                        <Menu as="div" className="relative ml-auto">
                                            <Menu.Button
                                                className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">Open options</span>
                                                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a
                                                                href="#"
                                                                onClick={() => handleEdit(issue.uuid)}
                                                                className={classNames(
                                                                    active ? 'bg-gray-50' : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                                )}
                                                            >
                                                                Edit<span className="sr-only">, {issue.issue}</span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a
                                                                href="#"
                                                                onClick={() => handleDeleteIssue(issue.uuid)}
                                                                className={classNames(
                                                                    active ? 'bg-gray-50' : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                                )}
                                                            >
                                                                Delete<span className="sr-only">, {issue.uuid}</span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-xs leading-6">
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Priority</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className={classNames(
                                                    priorities[issue.priority as Priority] ?? statuses.Hold,
                                                    'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                )}>{issue.priority}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Status</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className={classNames(
                                                    statuses[issue.status as Status] ?? statuses.Hold,
                                                    'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                )}>{issue.status}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Details</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900">{issue.details}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Action needed</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900">{issue.actionNeeded}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Labor cost estimate</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900">{issue.estimatedLaborCost}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Parts cost estimate</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900">{issue.estimatedPartsCost}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500">Required parts</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900">{issue.partsNeeded}</div>
                                            </dd>
                                        </div>
                                        {/*<div className="flex justify-between gap-x-4 py-1">*/}
                                        {/*    <dt className="text-gray-500">Cycle</dt>*/}
                                        {/*    <dd className="flex items-start gap-x-2">*/}
                                        {/*        <div className="text-gray-900">{issue.threshold} {issue.cycleType}</div>*/}
                                        {/*    </dd>*/}
                                        {/*</div>*/}
                                        {/*<div className="flex justify-between gap-x-4 py-1">*/}
                                        {/*    <dt className="text-gray-500">Should be done by date</dt>*/}
                                        {/*    <dd className="flex items-start gap-x-2">*/}
                                        {/*        <div*/}
                                        {/*            className="text-gray-900">{issue.nextDueDate}</div>*/}
                                        {/*    </dd>*/}
                                        {/*</div>*/}
                                        {/*<div className="flex justify-between gap-x-4 py-1">*/}
                                        {/*    <dt className="text-gray-500">Last done FH</dt>*/}
                                        {/*    <dd className="flex items-start gap-x-2">*/}
                                        {/*        <div*/}
                                        {/*            className="text-gray-900">{issue.lastDoneHours}</div>*/}
                                        {/*    </dd>*/}
                                        {/*</div>*/}
                                        {/*<div className="flex justify-between gap-x-4 py-1">*/}
                                        {/*    <dt className="text-gray-500">Next due FH</dt>*/}
                                        {/*    <dd className="flex items-start gap-x-2">*/}
                                        {/*        <div*/}
                                        {/*            className="text-gray-900">{issue.nextDueHours}</div>*/}
                                        {/*    </dd>*/}
                                        {/*</div>*/}
                                        {/*<div className="flex justify-between gap-x-4 py-1">*/}
                                        {/*    <dt className="text-gray-500">Current FH</dt>*/}
                                        {/*    <dd className="flex items-start gap-x-2">*/}
                                        {/*        <div*/}
                                        {/*            className="text-gray-900">{issue.currentHours}</div>*/}
                                        {/*    </dd>*/}
                                        {/*</div>*/}
                                    </dl>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>


        </div>

    )
}

export default AircraftIssueSection