import React, {useEffect, useState} from "react";
import apiClient from "../../tools/AxiosInterceptor";
import AddFlightSection from './AddFlightSection'
import {
    PlusCircleIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    FunnelIcon,
    CheckIcon,
    ChevronUpDownIcon
} from "@heroicons/react/20/solid";
import {Combobox, Disclosure} from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import {Flight, User} from "../../types";
import {classNames} from "../../utils/classNames";

interface UsersResponse {
    users: User[];
}

const FlightManagementSection: React.FC = () => {
    const [flights, setFlights] = useState<Flight[]>([]);
    const [filteredFlights, setFilteredFlights] = useState<Flight[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [currentFlight, setCurrentFlight] = useState<Flight | undefined>(undefined);
    const [expandedMonth, setExpandedMonth] = useState<string | null>(null);
    const [startDateTime, setStartDateTime] = useState<string>('');
    const [endDateTime, setEndDateTime] = useState<string>('');
    const [pilots, setPilots] = useState<User[]>([]);
    const [selectedPilot, setSelectedPilot] = useState<User | null>(null); // Dropdown for pilots
    const [query, setQuery] = useState(''); // Store search query for the Combobox

    useEffect(() => {
        fetchFlights();
        fetchPilots();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [startDateTime, endDateTime]);  // Call applyFilter when the date range changes

    useEffect(() => {
        applyFilter();
    }, [selectedPilot]);  // Call applyFilter when the selected pilot changes

    const navigate = useNavigate();

    const handleAddFlight = () => {
        navigate('/dashboard/flights/add'); // Adjust the path based on your routing structure
    };
    const fetchFlights = async () => {
        try {
            const response = await apiClient.get('/getFlights', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);
            setFlights(response.data.entries);
            setFilteredFlights(response.data.entries); // Initialize filtered flights
            const groupedFlights = groupFlightsByMonth(response.data.entries);
            setExpandedMonth(findMostRecentMonthWithFlights(groupedFlights));
        } catch (err: any) {
            setError(err.message || "Unable to fetch flights.");
        }
    };

    const fetchPilots = async () => {
        try {
            const response = await apiClient.get<UsersResponse>('/getUsers', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);

            setPilots(response.data.users);
        } catch (err: unknown) {
            if (err instanceof Error) {
                setError(err.message);
                console.log(error);
            } else {
                setError("An unknown error occurred");
                console.log(error);
            }
        }
    };

    const applyFilter = () => {
        const filtered = flights.filter((flight) => {
            const flightDateTime = new Date(`${flight.flight_date.split('T')[0]}T${flight.departure_time}`);
            const startDate = startDateTime ? new Date(startDateTime) : null;
            const endDate = endDateTime ? new Date(endDateTime) : null;

            const pilotFullName = selectedPilot
                ? `${selectedPilot.first_name.toLowerCase()} ${selectedPilot.last_name.toLowerCase()}`
                : null;

            const matchPilot = selectedPilot === null ||
                flight.pilot_in_command.toLowerCase() === pilotFullName;

            const matchDate = (!startDate || flightDateTime >= startDate) && (!endDate || flightDateTime <= endDate);

            return matchDate && matchPilot;
        });

        setFilteredFlights(filtered);
    };

    const filteredPilots =
        query === ''
            ? pilots.slice(0, 2)
            : pilots.filter((pilot) =>
                `${pilot.first_name} ${pilot.last_name}`.toLowerCase().includes(query.toLowerCase())
            );

    const handleStartDateChange = (value: string) => {
        setStartDateTime(value);
        applyFilter();
    };

    const handleEndDateChange = (value: string) => {
        setEndDateTime(value);
        applyFilter();
    };

    const handleEditFlight = (uuid: string) => {
        navigate(`/dashboard/flights/edit/${uuid}`);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('pl-PL');
    };

    const groupFlightsByMonth = (flights: Flight[]) => {
        const groupedFlights = flights
            .reduce((groups, flight) => {
                const date = new Date(flight.flight_date);
                const monthYear = date.toLocaleString('default', {month: 'long', year: 'numeric'});
                if (!groups[monthYear]) {
                    groups[monthYear] = [];
                }
                groups[monthYear].push(flight);
                return groups;
            }, {} as Record<string, Flight[]>);

        return Object.keys(groupedFlights)
            .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
            .reduce((acc, key) => {
                acc[key] = groupedFlights[key];
                return acc;
            }, {} as Record<string, Flight[]>);
    };

    const findMostRecentMonthWithFlights = (groupedFlights: Record<string, Flight[]>) => {
        const months = Object.keys(groupedFlights).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
        for (let month of months) {
            if (groupedFlights[month].length > 0) {
                return month;
            }
        }
        return null;
    };

    const flightsByMonth = groupFlightsByMonth(filteredFlights); // Ensure filteredFlights is used here

    function calculateTotalEngineTime(flights: Flight[]) {
        const totalEngineTime = flights.reduce((total, flight) => {
            return total + flight.engine_time_difference;
        }, 0);

        // Round the total engine time to one decimal place
        const roundedTotalEngineTime = totalEngineTime.toFixed(1);

        return `${roundedTotalEngineTime} MTH`;
    }

    return (
        <main>
            <div className="px-4 sm:px-6 lg:px-8 pb-8">
                {/* Header and Add Flight Button */}
                <div className="mt-8 px-4 pb-4 sm:px-6 lg:px-8">
                    <div className="lg:flex lg:items-center lg:justify-between">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                Flights
                            </h2>
                            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                                <div className="mt-2 flex items-center text-m text-gray-500">
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <button
                                    type="button"
                                    onClick={handleAddFlight}
                                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
                                    Add Flight
                                </button>
                            </span>
                        </div>
                    </div>
                </div>

                {/* Filters */}
                <Disclosure as="section" aria-labelledby="filter-heading"
                            className="grid items-center border-b border-t border-gray-200">
                    <h2 id="filter-heading" className="sr-only">
                        Filters
                    </h2>
                    <div className="relative col-start-1 row-start-1 py-4">
                        <div
                            className="mx-auto flex  space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
                            <div>
                                <Disclosure.Button
                                    className="group flex items-center font-medium text-gray-700">
                                    <FunnelIcon aria-hidden="true"
                                                className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"/>
                                    Filters
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="border-t border-gray-200 py-4">
                        <div
                            className="mx-auto grid grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
                            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                                <fieldset>
                                    <legend className="block font-medium">Date Range</legend>
                                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                        <input
                                            type="datetime-local"
                                            value={startDateTime}
                                            onChange={(e) => handleStartDateChange(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <input
                                            type="datetime-local"
                                            value={endDateTime}
                                            onChange={(e) => handleEndDateChange(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </fieldset>
                                {/* Pilot in Command Filter */}
                                <fieldset>
                                    <legend className="block font-medium">Pilot in Command</legend>
                                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                        <Combobox value={selectedPilot}
                                                  onChange={(pilot) => setSelectedPilot(pilot)}>
                                            <div className="relative">
                                                <Combobox.Input
                                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(event) => setQuery(event.target.value)}
                                                    displayValue={(pilot: User | null) => pilot ? `${pilot.first_name} ${pilot.last_name}` : 'All Pilots'}
                                                    placeholder="Select a pilot"
                                                />
                                                <Combobox.Button
                                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400"
                                                                       aria-hidden="true"/>
                                                </Combobox.Button>

                                                <Combobox.Options
                                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                >
                                                    {/* "All Pilots" option */}
                                                    <Combobox.Option
                                                        value={null} // This represents the "All Pilots" option
                                                        className={({active}) =>
                                                            classNames(
                                                                'relative cursor-default select-none py-2 pl-8 pr-4',
                                                                active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                            )
                                                        }
                                                    >
                                                        {({active, selected}) => (
                                                            <>
                                                                <span
                                                                    className={classNames('block truncate', selected ? 'font-semibold' : '')}>
                                                                    All Pilots
                                                                </span>

                                                                {selected && (
                                                                    <span
                                                                        className={classNames(
                                                                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                            active ? 'text-white' : 'text-indigo-600'
                                                                        )}
                                                                    >
                                                                        <CheckIcon className="h-5 w-5"
                                                                                   aria-hidden="true"/>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </Combobox.Option>

                                                    {/* Limit to 3 filtered pilots */}
                                                    {filteredPilots.map((pilot) => (
                                                        <Combobox.Option
                                                            key={pilot.uuid}
                                                            value={pilot}
                                                            className={({active}) =>
                                                                classNames(
                                                                    'relative cursor-default select-none py-2 pl-8 pr-4',
                                                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                                )
                                                            }
                                                        >
                                                            {({active, selected}) => (
                                                                <>
                                                                    <span
                                                                        className={classNames('block truncate', selected ? 'font-semibold' : '')}>
                                                                        {pilot.first_name} {pilot.last_name}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                                active ? 'text-white' : 'text-indigo-600'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5"
                                                                                       aria-hidden="true"/>
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </Combobox.Options>

                                            </div>
                                        </Combobox>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </Disclosure>

                {/* Flights List */}
                {Object.entries(flightsByMonth).map(([monthYear, monthlyFlights]) => (
                    <div key={monthYear} className="mt-8">
                        <div className="border-b border-gray-200 pb-5 cursor-pointer"
                             onClick={() => setExpandedMonth(expandedMonth === monthYear ? null : monthYear)}>
                            <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                {expandedMonth === monthYear ? (
                                    <ChevronDownIcon className="ml-2 mt-2 h-5 w-5 text-gray-500"/>
                                ) : (
                                    <ChevronRightIcon className="ml-2 mt-2 h-5 w-5 text-gray-500"/>
                                )}
                                <h3 className="ml-2 mt-2 text-lg font-semibold leading-6 text-gray-900">
                                    {monthYear}
                                </h3>
                                <p className="ml-2 mt-1 truncate text-xs text-gray-500">{calculateTotalEngineTime(monthlyFlights)}</p>
                            </div>
                        </div>
                        {expandedMonth === monthYear && (
                            <div>
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                            Aircraft and Date
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Pilot In Command
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Route
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Flight / Ground
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Engine
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Oil
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Fuel
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                            Remarks
                                        </th>
                                        <th scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {monthlyFlights.map((flight, flightIdx) => (
                                        <tr key={flight.uuid}>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'py-2 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                            )}>
                                                <div
                                                    className="font-medium text-gray-900">{flight.aircraft_registration}</div>
                                                <div
                                                    className="mt-1 text-gray-500">{formatDate(flight.flight_date)}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                            )}>
                                                <div
                                                    className="font-medium text-gray-900">{flight.pilot_in_command}</div>
                                                <div
                                                    className="mt-1 text-gray-500">{flight.license_number}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'py-2 px-3 text-xs text-gray-500 '
                                            )}>
                                                <div
                                                    className="text-gray-900">{flight.departure_airfield} {flight.departure_time}</div>
                                                <div
                                                    className="mt-1 text-gray-900">{flight.arrival_airfield} {flight.arrival_time}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                            )}>
                                                <div
                                                    className="text-gray-900">{flight.flight_time} / {flight.ground_time}</div>
                                                <div
                                                    className="mt-1 text-gray-500">Landings {flight.number_of_landings}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'py-2 px-3 text-xs text-gray-500 '
                                            )}>
                                                <div
                                                    className="text-gray-900">{flight.engine_time_before_flight} - {flight.engine_time_after_flight}</div>
                                                <div
                                                    className="mt-1 text-gray-500">Used {flight.engine_time_difference}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                            )}>
                                                <div
                                                    className="text-gray-900">{flight.oil_on_inspection} / {flight.oil_on_departure}</div>
                                                <div
                                                    className="mt-1 text-gray-500">Added {flight.oil_added}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                            )}>
                                                <div
                                                    className="text-gray-900">{flight.fuel_on_inspection} + {flight.fuel_added};
                                                    TO: {flight.fuel_on_departure}</div>
                                                <div
                                                    className="mt-1 text-gray-500">Landing {flight.fuel_on_landing};
                                                    Used {flight.fuel_used}</div>
                                            </td>

                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                            )}>
                                                <div className="text-gray-900">{flight.remarks}</div>
                                            </td>
                                            <td className={classNames(
                                                flightIdx !== monthlyFlights.length - 1 ? 'border-b border-gray-200' : '',
                                                'relative whitespace-nowrap py-2 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                                            )}>
                                                {sessionStorage.getItem('user_role') === 'admin' && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleEditFlight(flight.uuid)}
                                                        className="text-indigo-600 hover:text-indigo-900 text-xs"
                                                    >
                                                        Edit<span className="sr-only">, {flight.uuid}</span>
                                                    </button>
                                                )}
                                            </td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {error && <div className="text-red-600 px-4 sm:px-6 lg:px-8">{error}</div>}
        </main>
    );
};

export default FlightManagementSection;
