// src/hooks/useUsers.ts
import { useState, useEffect } from 'react';
import apiClient from '../tools/AxiosInterceptor';
import {User} from "../types";

export const useUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await apiClient.get('/getUsers', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });
                setUsers(response.data.users);
            } catch (err: any) {
                setError(err.message || 'Unable to fetch users.');
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    return { users, error, loading };
};
