import React, {useEffect, useState} from 'react';
import apiClient from "../../tools/AxiosInterceptor";
import {UserCircleIcon, ListBulletIcon} from '@heroicons/react/24/outline'
import md5 from "md5";

interface User {
    uuid: string;
    username: string;
    first_name: string;
    last_name: string;
    password: string;
    email: string;
    pilot_license_number?: string; // Optional since it might not be present in all user objects
    role: string;
    imageUrl?: string; // Optional as it might not be present in all user objects
}

interface Flight {
    uuid: string;
    aircraft_registration: string;
    arrival_airfield: string;
    arrival_time: string;
    departure_airfield: string;
    departure_time: string;
    engine_time_after_flight: number;
    engine_time_before_flight: number;
    engine_time_difference: number;
    flight_date: string;
    flight_time: string;
    fuel_added: number;
    fuel_on_departure: number;
    fuel_on_inspection: number;
    fuel_on_landing: number;
    fuel_used: number;
    ground_time: string;
    license_number: string;
    number_of_landings: number;
    oil_added: number;
    oil_on_departure: number;
    oil_on_inspection: number;
    pic_user_uuid: string;
    pilot_in_command: string;
    submittedAt: string;
}

const UserProfileSection = () => {
    const [user, setUser] = useState<User | null>(null);
    const [userFlights, setUserFlights] = useState<Flight[]>([]);
    const [activeSection, setActiveSection] = useState<string>('Profile');

    const secondaryNavigation = [
        {name: 'Profile', href: '#', icon: UserCircleIcon, current: activeSection === 'Profile'},
        {name: 'My Flights', href: '#', icon: ListBulletIcon, current: activeSection === 'My Flights'}
    ];

    const getEmailHash = (email : string) => {
        return md5(email.trim().toLowerCase());
    };

    const userEmail = sessionStorage.getItem('email');
    const imageSize = 200; // Specify the desired size, e.g., 200 pixels
    const gravatarUrl = userEmail
        ? `https://www.gravatar.com/avatar/${getEmailHash(userEmail)}?s=${imageSize}`
        : `https://www.gravatar.com/avatar/?d=identicon&s=${imageSize}`;

    const handleChangeAvatar = () => {
        window.open('https://en.gravatar.com/emails/', '_blank');
    };

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const handleNavClick = (sectionName: string) => {
        setActiveSection(sectionName);
    };

    const calculateTotalFlightTime = (flights: Flight[]): string => {
        const totalMinutes = flights.reduce((total: number, flight: Flight) => {
            return total + parseFlightTimeToMinutes(flight.flight_time);
        }, 0);

        return formatTimeInHHMM(totalMinutes);
    };

    const calculateTotalMTH = (flights: Flight[]): string => {
        const totalMTH = flights.reduce((total: number, flight: Flight) => total + flight.engine_time_difference, 0);
        return totalMTH.toFixed(1); // Round to 2 decimal places
    };

    const parseFlightTimeToMinutes = (timeString: string): number => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const formatTimeInHHMM = (totalMinutes: number): string => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    };

    const padToTwoDigits = (number: number): string => {
        return number.toString().padStart(2, '0');
    };

    const groupFlightsByMonth = (flights: Flight[]): Record<string, Flight[]> => {
        return flights.reduce((groups: Record<string, Flight[]>, flight) => {
            const monthYear = new Date(flight.flight_date).toLocaleString('default', {month: 'long', year: 'numeric'});
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(flight);
            return groups;
        }, {});
    };

    const groupedFlights = groupFlightsByMonth(userFlights);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userUuid = sessionStorage.getItem('user_uuid');
                if (userUuid) {
                    const response = await apiClient.get<User>(`getUser/${userUuid}`, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    } as any);
                    console.log("User.ts data fetched:", response.data);
                    setUser(response.data)
                    // Process the response data as needed
                } else {
                    console.error("User.ts UUID not found in sessionStorage");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchFlights = async () => {
            try {
                const response = await apiClient.get<{ entries: Flight[] }>('/getFlights', {
                    headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
                } as any);
                const userUuid = sessionStorage.getItem('user_uuid');
                const filteredFlights = response.data.entries.filter(flight => flight.pic_user_uuid === userUuid);
                setUserFlights(filteredFlights);
            } catch (error) {
                console.error("Error fetching flights data:", error);
            }
        };

        if (user) {
            fetchFlights();
        }
    }, [user]);

    return (
        <div className="mx-auto max-w-7xl pt-6 lg:flex lg:gap-x-16 lg:px-8">
            <aside
                className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                <nav className="flex-none px-4 sm:px-6 lg:px-0">
                    <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                        {secondaryNavigation.map((item) => (
                            <li key={item.name}>
                                <a
                                    href={item.href}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleNavClick(item.name);
                                    }}
                                    className={classNames(
                                        item.current
                                            ? 'bg-gray-50 text-indigo-600'
                                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                            'h-6 w-6 shrink-0'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </aside>


            <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-6">
                {activeSection === 'Profile' && (
                    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                            <p className="mt-1 mb-6 text-sm leading-6 text-gray-500">
                                To update the information contact info@mgaero.eu
                            </p>

                            <div className="col-span-full flex items-center gap-x-8">
                                <img
                                    src={gravatarUrl}
                                    alt=""
                                    className="h-36 w-36 flex-none rounded-lg bg-gray-800 object-cover"
                                />
                                <div>
                                    <button
                                        type="button"
                                        onClick={handleChangeAvatar}
                                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Change avatar
                                    </button>
                                </div>
                            </div>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{user?.first_name} {user?.last_name}</div>
                                        <button type="button"
                                                className="font-semibold text-gray-300 hover:text-gray-100">
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email
                                        address
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{user?.email}</div>
                                        <button type="button"
                                                className="font-semibold text-gray-300 hover:text-gray-100">
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">License
                                        Number
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{user?.pilot_license_number}</div>
                                        <button type="button"
                                                className="font-semibold font-semibold text-gray-300 hover:text-gray-100">
                                            Update
                                        </button>
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Technical
                                Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">This information is for development
                                purposes only</p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Username
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{user?.username}</div>
                                        <button type="button"
                                                className="font-semibold text-gray-300 hover:text-gray-100">
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Role
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{user?.role}</div>
                                        <button type="button"
                                                className="font-semibold text-gray-300 hover:text-gray-100">
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">User UUID
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{user?.uuid}</div>
                                        <button type="button"
                                                className="font-semibold font-semibold text-gray-300 hover:text-gray-100">
                                            Update
                                        </button>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                )}
                {activeSection === 'My Flights' && (
                    <>
                        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">My Flights</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500">
                                    List of all my flights logged in the system.
                                </p>
                            </div>
                        </div>
                        <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-2">
                            <div key='Total Flight Time' className="flex flex-col bg-gray-400/5 p-8">
                                <dt className="text-sm font-semibold leading-6 text-gray-600">Total Flight Time</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{calculateTotalFlightTime(userFlights)}</dd>
                            </div>
                            <div key='Total Flight Time' className="flex flex-col bg-gray-400/5 p-8">
                                <dt className="text-sm font-semibold leading-6 text-gray-600">Total MTH</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{calculateTotalMTH(userFlights)}</dd>
                            </div>

                        </dl>
                        {Object.entries(groupedFlights).map(([monthYear, monthlyFlights]) => (
                            <div key={monthYear} className="mt-8">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">{monthYear}</h2>
                                <div className="mt-4">
                                    <div className="inline-block min-w-full py-2 align-middle">
                                        <div
                                            className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        A/C Registration
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Date
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Departure
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Arrival
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Flight Time
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        MTH
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                {monthlyFlights.map((flight) => (
                                                    <tr key={flight.uuid}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {flight.aircraft_registration}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(flight.flight_date).toLocaleDateString()}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flight.departure_airfield}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flight.arrival_airfield}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flight.flight_time}</td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flight.engine_time_difference}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <th
                                                        scope="row"
                                                        colSpan={4}
                                                        className="hidden pl-4 pr-3 pt-6 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                                                    >
                                                        Total Flight Time
                                                    </th>
                                                    <th scope="row"
                                                        className="pl-4 pr-3 pt-6 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                                        Total Flight Time
                                                    </th>
                                                    <td className="pl-3 pr-4 pt-6 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                                                        {calculateTotalFlightTime(monthlyFlights)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        scope="row"
                                                        colSpan={4}
                                                        className="hidden pl-4 pr-3 pt-4 pb-6 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                                                    >
                                                        Total MTH (Motor Time Hours)
                                                    </th>
                                                    <th scope="row"
                                                        className="pl-4 pr-3 pt-4 pb-6 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                                        Total MTH
                                                    </th>
                                                    <td className="pl-3 pr-4 pt-4 pb-6 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                                                        {calculateTotalMTH(monthlyFlights)}
                                                    </td>
                                                </tr>
                                                </tfoot>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </>
                )}
            </main>
        </div>
    );
};

export default UserProfileSection;
