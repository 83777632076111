import React, {useState, Fragment, useRef} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import {ADSBComplianceHistoryRecord} from "../../../../types";

interface AdsbHistoryModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (historyRecord: ADSBComplianceHistoryRecord) => void;
    initialHistoryRecord?: ADSBComplianceHistoryRecord;
}
export const ADSBHistoryModal: React.FC<AdsbHistoryModalProps> = ({
                                                                                    open,
                                                                                    onClose,
                                                                                    onSave,
                                                                                    initialHistoryRecord,
                                                                                }) => {
    const [historyRecord, setHistoryRecord] = useState<ADSBComplianceHistoryRecord>(initialHistoryRecord || {
        date: '',
        flightHoursPerformed: null,
        motorHoursPerformed: null,
        comment: '',
        details: '',
        confirmed: false,
        confirmationDocument: '',
    });
    const [error, setError] = useState<string[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            const { checked } = e.target as HTMLInputElement; // Type assertion for checkbox
            setHistoryRecord(prev => ({ ...prev, [name]: checked }));
        } else {
            setHistoryRecord(prev => ({ ...prev, [name]: value }));
        }
    };
    const handleSubmit = () => {
        const errors = [];
        // Validation checks
        if (!historyRecord.date) {
            errors.push("Date performed is required.");
        } else {
            // Check if date is in ISO format
            const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
            if (!isoDatePattern.test(new Date(historyRecord.date).toISOString())) {
                errors.push("Date must be in ISO format (YYYY-MM-DDTHH:MM:SS.sssZ).");
            }
        }

        // Validate that flightHoursPerformed is a number greater than 0
        const flightHours = historyRecord.flightHoursPerformed ? parseFloat(historyRecord.flightHoursPerformed.toString()) : 0;
        if (!flightHours || isNaN(flightHours) || flightHours < 0) {
            errors.push("Flight hours performed must be a number greater greater than or equal to 0.");
        }

        // Validate that motorHoursPerformed is a number greater than 0
        const motorHours = historyRecord.motorHoursPerformed ? parseFloat(historyRecord.motorHoursPerformed.toString()) : 0;
        if (!motorHours || isNaN(motorHours) || motorHours < 0) {
            errors.push("Motor hours performed must be a number greater than or equal to 0.");
        }

        if (errors.length > 0) {
            setError(errors);
            return; // Prevent form submission if there are validation errors
        }

        // Prepare the record for saving with proper types and validated values
        const recordToSave = {
            ...historyRecord,
            date: new Date(historyRecord.date).toISOString(), // Ensure date is in ISO format
            flightHoursPerformed: flightHours, // Use validated number
            motorHoursPerformed: motorHours, // Use validated number
        };

        onSave(recordToSave); // Save with corrected and validated values
        onClose(); // Close modal after save
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-3  sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base text-center font-semibold leading-6 text-gray-900">
                                            AD/SB compliance history record
                                        </Dialog.Title>
                                        {error.length > 0 && (
                                            <div className="rounded-md bg-red-50 p-4 mb-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <XCircleIcon className="h-5 w-5 text-red-400"
                                                                     aria-hidden="true"/>
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-red-800">There were
                                                            errors with your submission</h3>
                                                        <div className="mt-2 text-sm text-red-700">
                                                            <ul role="list" className="list-disc pl-5 space-y-1">
                                                                {error.map((err, index) => (
                                                                    <li key={index}>{err}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-4">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <div className="mt-4">
                                                    <label htmlFor="date"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Date Performed
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            name="date"
                                                            value={historyRecord.date}
                                                            onChange={handleChange}
                                                            required
                                                            id="date"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="flightHoursPerformed"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Flight Hours Performed
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="flightHoursPerformed"
                                                        value={historyRecord.flightHoursPerformed ?? ''}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Flight hours at maintenance"
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="motorHoursPerformed"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Motor Hours Performed
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="motorHoursPerformed"
                                                        value={historyRecord.motorHoursPerformed ?? ''}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Motor hours at maintenance"
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="comment"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Comment
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="comment"
                                                        value={historyRecord.comment}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Comment"
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="details"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Details
                                                    </label>
                                                    <textarea
                                                        name="details"
                                                        value={historyRecord.details}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Details"
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="confirmationDocument"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Confirmation Document
                                                    </label>
                                                    <textarea
                                                        name="confirmationDocument"
                                                        value={historyRecord.confirmationDocument}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Confirmation Document"
                                                    />
                                                </div>
                                                <div className="relative mt-4 flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="candidates"
                                                            aria-describedby="candidates-description"
                                                            name="confirmed"
                                                            type="checkbox"
                                                            checked={historyRecord.confirmed}
                                                            onChange={handleChange}
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label htmlFor="candidates"
                                                               className="font-medium text-gray-900">
                                                            Confirmed
                                                        </label>{' '}
                                                        <span id="candidates-description" className="text-gray-500">
              <span className="sr-only">Confirmed </span>successfully.
            </span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        onClick={handleSubmit}
                                    >
                                    Save
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
