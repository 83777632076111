import React, { useEffect } from 'react';
import {ChevronRightIcon, ChevronLeftIcon} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";


export default function PrivacyPolicy() {
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const handleBackClick = () => {
        navigate(-1); // Navigates back to the previous page
    };
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'pl';
        console.log("Changing language to:", storedLanguage);
        i18n.changeLanguage(storedLanguage);
    }, [i18n]);

    return (
        <div className="bg-white px-6 py-28 lg:px-8">
            <div className="mx-auto max-w-5xl text-base leading-7 text-gray-700">
                <button
                    onClick={handleBackClick}
                    className="flex pb-6 items-center text-gray-700 hover:text-gray-900"
                >
                    <ChevronLeftIcon className="w-5 h-5 mr-2"/>
                    Powrót
                </button>
                <h1 className="mt-2 text-5xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('privacyPolicy')}</h1>
                <div className="mt-10 pb-10 max-w-5xl">
                    <p className="pb-6 text-s">
                        Niniejsza Polityka Prywatności opisuje, jak MG Invest sp. z o.o., z siedzibą w Rabce-Zdrój, ul.
                        Orkana 20F / 5, Rabka-Zdrój 34-700, Polska, e-mail: info@mgaero.eu
                        zbiera, wykorzystuje i
                        chroni informacje o osobach odwiedzających naszą stronę internetową oraz naszych klientach i
                        kontrahentach.
                    </p>
                    <h2 className="my-6 text-xl font-bold tracking-tight text-gray-900">Klauzula Informacyjna dla
                        Klientów / Kontrahentów</h2>
                    <p className="pb-6 text-s">
                        Na podstawie art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
                        dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
                        osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                        (ogólne rozporządzenie o ochronie danych) (dalej: „RODO”) oraz z uwagi na to, że jest Pani/Pan
                        moim Klientem / Kontrahentem lub osobą, która reprezentuje interesy mojego Klienta / Kontrahenta
                        chciałabym przekazać Pani / Panu kilka ważnych informacji.
                    </p>

                    <ul role="list" className="mt-8 max-w-3l space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Kto jest administratorem Pani / Pana danych?</strong> Administratorem Danych Osobowych jest MG Invest sp. z o.o. z siedzibą w MG Invest sp. z o.o., z siedzibą w Rabce-Zdrój, ul.
                        Orkana 20F / 5, Rabka-Zdrój 34-700, Polska, e-mail: info@mgaero.eu (dalej: „Administrator”).
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Jaka jest podstawa prawna przetwarzania Pani / Pana danych? Dlaczego przetwarzamy Pani / Pana dane?</strong> Przetwarzamy Pani/Pana dane, ponieważ jest to niezbędne do wykonania umowy lub podjęcia działań związanych z zawarciem umowy (art. 6 ust.1 lit. b RODO*), jest to również niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO) (prawo podatkowe, rachunkowość). Podstawą prawną przetwarzania Pani/Pana danych jest również uzasadniony interes Administratora (art. 6 ust.1 lit. f RODO), który polega na ewentualnym ustaleniu, dochodzeniu lub obronie roszczeń, wynikłych na tle stosowania umowy, której jest Pani/Pan stroną.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Kto może być odbiorcą Pani / Pana danych?</strong> Odbiorcami Pani/Pana danych mogą być podmioty z nami współpracujące takie, jak np. księgowość, podmioty świadczący usługi finansowe, podmioty realizujące wysyłki, operatorzy pocztowi, banki, dostawcy usług IT, Urząd Lotnictwa Cywilnego.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong
                    className="font-semibold text-gray-900">Czy będziemy przekazywać Pani / Pana dane poza obszar EOG?</strong> Informujemy, że nie zamierzamy przekazywać Pani / Pana danych osobowych do państwa trzeciego (przez państwo trzecie należy rozumieć kraje spoza UE i EOG).
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong
                    className="font-semibold text-gray-900">Jak długo będziemy przetwarzać Pani / Pana dane?</strong> Pani / Pana dane będą przechowywane przez czas trwania umowy, a po rozwiązaniu umowy przez czas niezbędny do ewentualnego ustalenia, dochodzenia lub obrony roszczeń oraz przez czas niezbędny do wywiązania się przez Administratora z obowiązku prawnego wynikającego, w szczególności z ustawy o rachunkowości.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Jakie przysługują Pani / Pana prawa?</strong> Ma Pani/Pan prawo dostępu do danych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, przeniesienia danych, a także może Pani/Pan zgłosić sprzeciw w dowolnym momencie. Ma Pani/Pan prawo również wnieść skargę do organu nadzorczego. Nie podejmujemy zautomatyzowanych decyzji w odniesieniu do Pani/Pana danych. Podanie danych osobowych jest dobrowolne, lecz jest warunkiem zawarcia umowy, natomiast ich niepodanie skutkuje niemożliwością zawarcia lub wykonania umowy.
              </span>
                        </li>
                    </ul>
                </div>
                <div className="mt-10 pb-10 max-w-5xl">
                    <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900">Obowiązek informacyjny dla osób
                        wyznaczonych do kontaktu ze mną</h2>
                    <h3 className="mb-6 text-l tracking-tight text-gray-900">(np. pracownicy Klienta / Kontrahenta) -
                        pozyskane od osoby, której dane dotyczą</h3>

                    <p className="pb-6 text-s">
                        Na podstawie art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
                        dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
                        osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                        (ogólne rozporządzenie o ochronie danych) (dalej: „RODO”) oraz z uwagi na to, że jest Pani/Pan
                        moim Klientem / Kontrahentem lub osobą, która reprezentuje interesy mojego Klienta / Kontrahenta
                        chciałabym przekazać Pani / Panu kilka ważnych informacji.
                    </p>

                    <ul role="list" className="mt-8 max-w-3l space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Kto jest administratorem Pani / Pana danych?</strong> Administratorem Danych Osobowych jest MG Invest sp. z o.o. z siedzibą w MG Invest sp. z o.o., z siedzibą w Rabce-Zdrój, ul.
                        Orkana 20F / 5, Rabka-Zdrój 34-700, Polska, e-mail: info@mgaero.eu (dalej: „Administrator”).
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Jaka jest podstawa prawna przetwarzania Pani / Pana danych? Dlaczego przetwarzamy Pani / Pana dane?</strong> Przetwarzamy Pani/Pana dane, ponieważ jest to niezbędne do wykonania umowy lub podjęcia działań związanych z zawarciem umowy (art. 6 ust.1 lit. b RODO*), jest to również niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO) (prawo podatkowe, rachunkowość). Podstawą prawną przetwarzania Pani/Pana danych jest również uzasadniony interes Administratora (art. 6 ust.1 lit. f RODO), który polega na ewentualnym ustaleniu, dochodzeniu lub obronie roszczeń, wynikłych na tle stosowania umowy, której jest Pani/Pan stroną.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Kto może być odbiorcą Pani / Pana danych?</strong> Odbiorcami Pani/Pana danych mogą być podmioty ze nami współpracujące takie, jak np. księgowość, podmioty świadczący usługi finansowe, podmioty realizujące wysyłki, operatorzy pocztowi, banki, dostawcy usług IT, Urząd Lotnictwa Cywilnego.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong
                    className="font-semibold text-gray-900">Czy będziemy przekazywać Pani / Pana dane poza obszar EOG?</strong> Informujemy, że nie zamierzamy przekazywać Pani / Pana danych osobowych do państwa trzeciego (przez państwo trzecie należy rozumieć kraje spoza UE i EOG).
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong
                    className="font-semibold text-gray-900">Jak długo będziemy przetwarzać Pani / Pana dane?</strong> Pani / Pana dane będą przechowywane przez czas trwania umowy, a po rozwiązaniu umowy przez czas niezbędny do ewentualnego ustalenia, dochodzenia lub obrony roszczeń oraz przez czas niezbędny do wywiązania się przez Administratora z obowiązku prawnego wynikającego, w szczególności z ustawy o rachunkowości.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Jakie przysługują Pani / Pana prawa?</strong> Ma Pani/Pan prawo dostępu do danych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, przeniesienia danych, a także może Pani/Pan zgłosić sprzeciw w dowolnym momencie. Ma Pani/Pan prawo również wnieść skargę do organu nadzorczego. Nie podejmujemy zautomatyzowanych decyzji w odniesieniu do Pani/Pana danych. Podanie danych osobowych jest dobrowolne, lecz jest warunkiem zawarcia umowy, natomiast ich niepodanie skutkuje niemożliwością zawarcia lub wykonania umowy.
              </span>
                        </li>
                    </ul>
                </div>
                <div className="mt-10 pb-10 max-w-5xl">
                    <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900">Obowiązek informacyjny dla osób
                        wyznaczonych do kontaktu z nami</h2>
                    <h3 className="mb-6 text-l tracking-tight text-gray-900">(np. pracownicy Klienta / Kontrahenta) -
                        pozyskane NIE od osoby, której dane dotyczą</h3>

                    <p className="pb-6 text-s">
                        Na podstawie art. 14 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
                        dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
                        osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                        (ogólne rozporządzenie o ochronie danych) (dalej: „RODO”) oraz z uwagi na to, że jest Pani/Pan
                        moim Klientem / Kontrahentem lub osobą, która reprezentuje interesy mojego Klienta / Kontrahenta
                        chciałabym przekazać Pani / Panu kilka ważnych informacji.
                    </p>

                    <ul role="list" className="mt-8 max-w-3l space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Kto jest administratorem Pani / Pana danych?</strong> Administratorem Danych Osobowych jest MG Invest sp. z o.o. z siedzibą w MG Invest sp. z o.o., z siedzibą w Rabce-Zdrój, ul.
                        Orkana 20F / 5, Rabka-Zdrój 34-700, Polska, e-mail: info@mgaero.eu (dalej: „Administrator”).
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Jaka jest podstawa prawna przetwarzania Pani / Pana danych? Dlaczego przetwarzamy Pani / Pana dane?</strong> Przetwarzamy Pani/Pana dane, ponieważ jest to niezbędne do wykonania umowy lub podjęcia działań związanych z zawarciem umowy (art. 6 ust.1 lit. b RODO*), jest to również niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO) (prawo podatkowe, rachunkowość). Podstawą prawną przetwarzania Pani/Pana danych jest również uzasadniony interes Administratora (art. 6 ust.1 lit. f RODO), który polega na ewentualnym ustaleniu, dochodzeniu lub obronie roszczeń, wynikłych na tle stosowania umowy, której jest Pani/Pan stroną.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Kto może być odbiorcą Pani / Pana danych?</strong> Odbiorcami Pani/Pana danych mogą być podmioty ze nami współpracujące takie, jak np. księgowość, podmioty świadczący usługi finansowe, podmioty realizujące wysyłki, operatorzy pocztowi, banki, dostawcy usług IT, Urząd Lotnictwa Cywilnego.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong
                    className="font-semibold text-gray-900">Czy będziemy przekazywać Pani / Pana dane poza obszar EOG?</strong> Informujemy, że nie zamierzamy przekazywać Pani / Pana danych osobowych do państwa trzeciego (przez państwo trzecie należy rozumieć kraje spoza UE i EOG).
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong
                    className="font-semibold text-gray-900">Jak długo będziemy przetwarzać Pani / Pana dane?</strong> Pani / Pana dane będą przechowywane przez czas trwania umowy, a po rozwiązaniu umowy przez czas niezbędny do ewentualnego ustalenia, dochodzenia lub obrony roszczeń oraz przez czas niezbędny do wywiązania się przez Administratora z obowiązku prawnego wynikającego, w szczególności z ustawy o rachunkowości.
              </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                <strong className="font-semibold text-gray-900">Jakie przysługują Pani / Pana prawa?</strong> Ma Pani/Pan prawo dostępu do danych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, przeniesienia danych, a także może Pani/Pan zgłosić sprzeciw w dowolnym momencie. Ma Pani/Pan prawo również wnieść skargę do organu nadzorczego. Nie podejmujemy zautomatyzowanych decyzji w odniesieniu do Pani/Pana danych. Podanie danych osobowych jest dobrowolne, lecz jest warunkiem zawarcia umowy, natomiast ich niepodanie skutkuje niemożliwością zawarcia lub wykonania umowy.
              </span>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    )
}
