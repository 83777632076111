import React, {useState, useEffect} from 'react';
import {v4 as uuidv4} from "uuid";
import {Switch} from '@headlessui/react';
import {ADSBCompliance} from "../../../../types"

interface AddAdsbFormProps {
    onSubmit: (newAdsb: ADSBCompliance) => void;
    onCancel: () => void;
    adsb: ADSBCompliance | null;
}

const initialFormState = {
    uuid: uuidv4(), // Generates a new UUID
    airworthinessDirectiveId: '',
    serviceBulletinId: '',
    subject: '',
    method: '',
    repeatable: false,
    single: false,
    applicabilityCriteria: [{
        type: '',
        threshold: ''
    }],
    complianceHistory: [{
        date: '', // Empty string for initial date
        flightHoursPerformed: null, // Initialize as null
        motorHoursPerformed: null, // Initialize as null
        comment: '', // Empty string for initial comment
        details: '', // Empty string for initial details
        confirmed: false, // Initialize as false
        confirmationDocument: ''
    }]
};


const AdsbForm: React.FC<AddAdsbFormProps> = ({onSubmit, onCancel, adsb}) => {
    const [formValues, setFormValues] = useState<ADSBCompliance>(initialFormState);

    const initialApplicabilityCriterias = adsb && adsb.applicabilityCriteria.length > 0
        ? adsb.applicabilityCriteria
        : [{type: '', threshold: ''}]; // Single array with an empty object
    const [applicabilityCriterias, setApplicabilityCriterias] = useState<Array<{
        type: string;
        threshold: number | string
    }>>(initialApplicabilityCriterias);

    const initialComplianceHistoryEntries = adsb && adsb.complianceHistory.length > 0
        ? adsb.complianceHistory
        : [{
            date: '',
            flightHoursPerformed: null,
            motorHoursPerformed: null,
            comment: '',
            details: '',
            confirmed: false,
            confirmationDocument: ''
        }]; // Single array with an empty object
    const [complianceHistoryEntries, setComplianceHistoryEntries] = useState<Array<{
        date: string;
        flightHoursPerformed: number | null;
        motorHoursPerformed: number | null;
        comment: string;
        details: string;
        confirmed: boolean;
        confirmationDocument: string;
    }>>(initialComplianceHistoryEntries)

    useEffect(() => {
        if (adsb) {
            const formattedDate = adsb.complianceHistory[0].date
                ? new Date(adsb.complianceHistory[0].date).toISOString().split('T')[0]
                : '';

            setFormValues({
                uuid: adsb.uuid || uuidv4(),
                airworthinessDirectiveId: adsb.airworthinessDirectiveId || '',
                serviceBulletinId: adsb.serviceBulletinId || '',
                subject: adsb.subject || '',
                method: adsb.method || '',
                repeatable: adsb.repeatable || false,
                single: adsb.single || false,
                applicabilityCriteria: adsb.applicabilityCriteria && adsb.applicabilityCriteria.length > 0
                    ? adsb.applicabilityCriteria
                    : [{type: '', threshold: ''}],
                complianceHistory: adsb.complianceHistory && adsb.complianceHistory.length > 0
                    ? adsb.complianceHistory
                    : [{
                        date: '', // Empty string for initial date
                        flightHoursPerformed: null, // Initialize as null
                        motorHoursPerformed: null, // Initialize as null
                        comment: '', // Empty string for initial comment
                        details: '', // Empty string for initial details
                        confirmed: false, // Initialize as false
                        confirmationDocument: ''
                    }]
            });
        }
    }, [adsb]);


    const handleAddApplicabilityCriteria = () => {
        setApplicabilityCriterias(applicabilityCriterias => [...applicabilityCriterias, {type: '', threshold: ''}]);
    };

    const handleRemoveApplicabilityCriteria = (applicabilityCriteriaIndex: number) => {
        const newApplicabilityCriterias = applicabilityCriterias.filter((_, index) => index !== applicabilityCriteriaIndex);
        setApplicabilityCriterias(newApplicabilityCriterias);
    };


    const handleApplicabilityCriteriaChange = (cycleIndex: number, field: string, value: string | number) => {
        const newApplicabilityCriterias = applicabilityCriterias.map((applicabilityCriteria, idx) => {
            if (idx === cycleIndex) {
                // Update the specific field of the cycle
                return {...applicabilityCriteria, [field]: value};
            }
            return applicabilityCriteria;
        });
        setApplicabilityCriterias(newApplicabilityCriterias);
    };

    const handleComplianceHistoryChange = (historyIndex: number, field: string, value: string | number | boolean) => {
        const newComplianceHistoryEntries = complianceHistoryEntries.map((complianceHistoryEntry, idx) => {
            if (idx === historyIndex) {
                return {...complianceHistoryEntry, [field]: value};
            }
            return complianceHistoryEntry;
        });
        setComplianceHistoryEntries(newComplianceHistoryEntries);
    };


    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        console.log(name, value)

        setFormValues(prev => {
            // Split the name by '.' to handle nested fields
            const keys = name.split('.');
            const lastKey = keys.pop();
            console.log(lastKey, keys)

            // Reduce the keys to reach the final object that needs to be updated
            const lastObj = keys.reduce((obj: any, key: string) => {
                if (!obj[key]) {
                    obj[key] = {};
                }
                console.log(obj[key])
                return obj[key];
            }, prev);

            // Update the final nested object
            if (lastKey) {
                lastObj[lastKey] = value;
            }

            // Return the new state
            return {...prev};
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        console.log(name, event)
        setFormValues((prev) => {
            // Split the name by '.' to handle nested fields
            const keys = name.split('.');
            const lastKey = keys.pop();

            // Reduce the keys to reach the final object that needs to be updated
            const lastObj = keys.reduce((obj: any, key: string) => obj[key] = obj[key] || {}, prev);

            // Update the final nested object
            if (lastKey) {
                lastObj[lastKey] = value;
            }

            // Return the new state
            return {...prev};
        });
    };

    const toggleSingle = () => {
        setFormValues(prev => ({...prev, single: !prev.single}));
    };

    const toggleRepeatable = () => {
        setFormValues(prev => ({...prev, repeatable: !prev.repeatable}));
    };

    const toggleConfirmed = () => {
        setFormValues(prev => {
            // Clone the complianceHistory array
            const newComplianceHistory = [...prev.complianceHistory];

            // Toggle the confirmed status of the first item in the array
            if (newComplianceHistory.length > 0) {
                newComplianceHistory[0] = {
                    ...newComplianceHistory[0],
                    confirmed: !newComplianceHistory[0].confirmed
                };
            }

            // Return the updated form values
            return {
                ...prev,
                complianceHistory: newComplianceHistory
            };
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        // Clone formValues to avoid mutating the state directly
        const submitValues = {
            ...formValues,
            applicabilityCriteria: applicabilityCriterias.map(applicabilityCriteria => ({
                type: applicabilityCriteria.type,
                threshold: applicabilityCriteria.threshold
            })),
            complianceHistory: complianceHistoryEntries.map(complianceHistoryEntry => (
                {
                    date: complianceHistoryEntry.date,
                    flightHoursPerformed: complianceHistoryEntry.flightHoursPerformed,
                    motorHoursPerformed: complianceHistoryEntry.motorHoursPerformed,
                    comment: complianceHistoryEntry.comment,
                    details: complianceHistoryEntry.details,
                    confirmed: complianceHistoryEntry.confirmed,
                    confirmationDocument: complianceHistoryEntry.confirmationDocument
                }
            ))
        };

        // Validate and potentially transform the cycle array
        submitValues.applicabilityCriteria = submitValues.applicabilityCriteria.map(applicabilityCriteriaItem => ({
            type: applicabilityCriteriaItem.type,
            threshold: applicabilityCriteriaItem.threshold // Ensure this is correctly formatted as a number or string
        }));

        // Perform any additional validation here if necessary
        if (submitValues.complianceHistory[0].date) {
            submitValues.complianceHistory[0].date = new Date(submitValues.complianceHistory[0].date).toISOString();
        }

        // Call onSubmit with the prepared data
        onSubmit(submitValues);

        // Reset formValues after submission
        setFormValues(initialFormState);
    };

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <div>
            <main>
                <div className="mt-8 px-4 sm:px-6 lg:px-8 ">
                    <div className="space-y-10 divide-y divide-gray-900/10">
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                                <div className="px-4 sm:px-0">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">General</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Airworthiness Directive and Service Bulletin details
                                    </p>
                                </div>

                                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                                    <div className="px-4 py-4 sm:p-8">
                                        <div className="grid max-w-2xl py-4 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                            <div className="sm:col-span-2">
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Airworthiness Directive ID
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        rows={1}
                                                        name="airworthinessDirectiveId"
                                                        id="airworthinessDirectiveId"
                                                        value={formValues.airworthinessDirectiveId}
                                                        onChange={handleTextAreaChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Service Bulletin ID
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        rows={1}
                                                        name="serviceBulletinId"
                                                        id="serviceBulletinId"
                                                        value={formValues.serviceBulletinId}
                                                        onChange={handleTextAreaChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Subject
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        rows={4}
                                                        name="subject"
                                                        id="subject"
                                                        value={formValues.subject}
                                                        onChange={handleTextAreaChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                    Method
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        rows={4}
                                                        name="method"
                                                        id="method"
                                                        value={formValues.method}
                                                        onChange={handleTextAreaChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                {/* Toggle for 'single' */}

                                                <Switch.Group as="div" className="flex items-center">
                                                    <Switch
                                                        checked={formValues.single}
                                                        onChange={toggleSingle}
                                                        className={classNames(
                                                            formValues.single === true ? 'bg-indigo-600' : 'bg-gray-200',
                                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                                        )}
                                                    >
        <span
            aria-hidden="true"
            className={classNames(
                formValues.single === true ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
                                                    </Switch>
                                                    <Switch.Label as="span" className="ml-3 text-sm">
                                                        <span
                                                            className="font-medium text-gray-900">Single</span>{' '}
                                                        <span
                                                            className="text-gray-500">(Only has to be performed once)</span>
                                                    </Switch.Label>
                                                </Switch.Group>
                                            </div>
                                            <div className="sm:col-span-2">
                                                {/* Toggle for 'repeatable' */}
                                                <Switch.Group as="div" className="flex items-center">
                                                    <Switch
                                                        checked={formValues.repeatable}
                                                        onChange={toggleRepeatable}
                                                        className={classNames(
                                                            formValues.repeatable === true ? 'bg-indigo-600' : 'bg-gray-200',
                                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                                        )}
                                                    >
        <span
            aria-hidden="true"
            className={classNames(
                formValues.repeatable === true ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
                                                    </Switch>
                                                    <Switch.Label as="span" className="ml-3 text-sm">
                                                        <span
                                                            className="font-medium text-gray-900">Repeatable</span>{' '}
                                                        <span className="text-gray-500">(Recurring activity)</span>
                                                    </Switch.Label>
                                                </Switch.Group>

                                            </div>

                                        </div>
                                        <div>
                                            <ul role="list" className="border-gray-200 text-sm leading-6">
                                                {applicabilityCriterias.map((applicabilityCriteria, applicabilityCriteriaIndex) => (
                                                    <li key={applicabilityCriteriaIndex}
                                                        className="flex justify-between items-center gap-x-6 py-3">
                                                        <div
                                                            className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                                            {/* Recurrence select input */}
                                                            <div className="sm:col-span-3">
                                                                <label
                                                                    htmlFor={`cycleType-${applicabilityCriteriaIndex}`}
                                                                    className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Recurrence
                                                                </label>
                                                                <div className="mt-2">
                                                                    <select
                                                                        id={`cycleType-${applicabilityCriteriaIndex}`}
                                                                        name="cycle.type"
                                                                        value={applicabilityCriteria.type}
                                                                        onChange={(e) => handleApplicabilityCriteriaChange(applicabilityCriteriaIndex, 'type', e.target.value)}
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                                    >
                                                                        <option></option>
                                                                        <option>Hourly</option>
                                                                        <option>Monthly</option>
                                                                        <option>Special</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* Threshold input */}
                                                            <div className="sm:col-span-3">
                                                                <label
                                                                    htmlFor={`cycleThreshold-${applicabilityCriteriaIndex}`}
                                                                    className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Threshold
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="cycle.threshold"
                                                                        id={`cycleThreshold-${applicabilityCriteriaIndex}`}
                                                                        autoComplete="cycle.threshold"
                                                                        value={applicabilityCriteria.threshold}
                                                                        onChange={(e) => handleApplicabilityCriteriaChange(applicabilityCriteriaIndex, 'threshold', e.target.value)}
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {applicabilityCriteriaIndex > 0 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRemoveApplicabilityCriteria(applicabilityCriteriaIndex)}
                                                                className="font-semibold text-indigo-600 hover:text-indigo-500"
                                                            >
                                                                <span aria-hidden="true">-</span> Remove
                                                            </button>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>


                                            <div className="mt-4">
                                                <button
                                                    type="button"
                                                    onClick={handleAddApplicabilityCriteria}
                                                    className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                                >
                                                    <span aria-hidden="true">+</span> Add Cycle
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                                <div className="px-4 sm:px-0">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Compliance
                                        History</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Details on compliance
                                    </p>
                                </div>
                                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                                    <div className="px-4 py-4 sm:p-8">
                                        {complianceHistoryEntries.map((historyItem, index) => (
                                            <div
                                                className="grid max-w-2xl py-4 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Details
                                                    </label>
                                                    <div className="mt-2">
                                                    <textarea
                                                        rows={2}
                                                        name={`complianceHistory[${index}].details`}
                                                        id={`complianceHistory[${index}].details`}
                                                        value={historyItem.details}
                                                        onChange={(e) => handleComplianceHistoryChange(index, 'details', e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Comment
                                                    </label>
                                                    <div className="mt-2">
                                                    <textarea
                                                        rows={2}
                                                        name={`complianceHistory[${index}].comment`}
                                                        id={`complianceHistory[${index}].comment`}
                                                        value={historyItem.comment}
                                                        onChange={(e) => handleComplianceHistoryChange(index, 'comment', e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Flight Hours when Performed
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="number"
                                                            name={`complianceHistory[${index}].flightHoursPerformed`}
                                                            id={`complianceHistory[${index}].flightHoursPerformed`}
                                                            value={(historyItem.flightHoursPerformed != null) ? (historyItem.flightHoursPerformed) : 0}
                                                            onChange={(e) => handleComplianceHistoryChange(index, 'flightHoursPerformed', e.target.value)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        MTH when Performed
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="number"
                                                            name={`complianceHistory[${index}].motorHoursPerformed`}
                                                            id={`complianceHistory[${index}].motorHoursPerformed`}
                                                            value={(historyItem.motorHoursPerformed != null) ? (historyItem.motorHoursPerformed) : 0}
                                                            onChange={(e) => handleComplianceHistoryChange(index, 'motorHoursPerformed', e.target.value)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Date
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            name={`complianceHistory[${index}].date`}
                                                            id={`complianceHistory[${index}].date`}
                                                            value={historyItem.date
                                                                ? new Date(historyItem.date).toISOString().split('T')[0]
                                                                : ''}
                                                            onChange={(e) => handleComplianceHistoryChange(index, 'date', e.target.value)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Confirmation Document
                                                    </label>
                                                    <div className="mt-2">
                                                    <textarea
                                                        rows={1}
                                                        name={`complianceHistory[${index}].confirmationDocument`}
                                                        id={`complianceHistory[${index}].confirmationDocument`}
                                                        value={historyItem.confirmationDocument}
                                                        onChange={(e) => handleComplianceHistoryChange(index, 'confirmationDocument', e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                    </div>
                                                </div>
                                                <div className="flex items-center sm:col-span-2">
                                                    {/* Toggle for 'single' */}

                                                    <Switch.Group as="div" className="flex items-center">
                                                        <Switch
                                                            checked={historyItem.confirmed === true}
                                                            onChange={() => handleComplianceHistoryChange(index, 'confirmed', !historyItem.confirmed)}
                                                            className={classNames(
                                                                historyItem.confirmed === true ? 'bg-indigo-600' : 'bg-gray-200',
                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                                            )}
                                                        >
        <span
            aria-hidden="true"
            className={classNames(
                historyItem.confirmed === true ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
                                                        </Switch>
                                                        <Switch.Label as="span" className="ml-3 text-sm">
                                                        <span
                                                            className="font-medium text-gray-900">Confirmed</span>{' '}
                                                            <span
                                                                className="text-gray-500">(Compliance confirmed)</span>
                                                        </Switch.Label>
                                                    </Switch.Group>
                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className=" flex items-center justify-end gap-x-6 gap-y-8 p-8">
                                <button
                                    type="button"
                                    onClick={onCancel}
                                    className="text-sm font-semibold leading-6 text-gray-900">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>

    )
        ;
};

export default AdsbForm;