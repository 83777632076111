import React, {useEffect, useMemo, useState} from 'react';
import apiClient from "../../../tools/AxiosInterceptor";
import AircraftDetails from "./AircraftDetailsSection";
import AircraftComponentSection from "./AddEditViewForms/AircraftComponentSection";
import {Aircraft, InstalledComponent, ComponentStatus} from "../../../types";
import AircraftIssueSection from "./AddEditViewForms/AircraftIssueSection";

interface AircraftData {
    aircraft: Aircraft[];
}

enum UIState {
    AircraftDetails,
    AddComponentForm,
    AircraftIssues
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const AircraftManagementContainer = () => {
    const [aircraftData, setAircraftData] = useState<AircraftData>({aircraft: []});
    const [error, setError] = useState<string | null>(null);
    const [currentUI, setCurrentUI] = useState<UIState>(UIState.AircraftDetails);
    const [selectedComponent, setSelectedComponent] = useState<InstalledComponent | undefined>(undefined);


    useEffect(() => {
        fetchAircraftData();
    }, []);
    const fetchAircraftData = async () => {
        try {
            // Fetch the list of aircraft
            const listResponse = await apiClient.get('/v1/getAircraft', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);

            const aircraftList = listResponse.data;

            if (aircraftList.length > 0) {
                // Fetch full details of the first aircraft
                const firstAircraftUuid = aircraftList[0].uuid;
                const fullDetailsResponse = await apiClient.get(`/v1/getAircraft?uuid=${firstAircraftUuid}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                } as any);

                // Set the full details of the first aircraft into state
                setAircraftData({aircraft: [fullDetailsResponse.data]});
            } else {
                // Handle the case where no aircraft are returned
                setAircraftData({aircraft: []});
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError("Unable to fetch aircraft data.");
            }
        }
    };

    function monthDiffWithDecimal(dateFrom: Date, dateTo: Date): number {
        let months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
        months += dateTo.getMonth() - dateFrom.getMonth();

        // Calculate the fraction of the month based on days
        const daysInFromMonth = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).getDate(); // Total days in the month of dateFrom
        const dayFraction = (dateTo.getDate() - dateFrom.getDate()) / daysInFromMonth;

        // Combine full month difference with the fractional part
        const totalMonths = months + dayFraction;

        // Round to 1 decimal place
        return Math.round(totalMonths * 10) / 10;
    }

    function getNextMaintenanceDetails(component: InstalledComponent): {
        minHoursDifference: number | null,
        minMonthsDifference: number | null
        nextServiceHours: number | null,
        nextServiceDate: Date | null,
        componentHourlyServiced: boolean,
        componentCalendarServiced: boolean,
        overallHoursStatus: ComponentStatus,
        overallCalendarStatus: ComponentStatus,
        upcomingMaintenance: Array<{
            type: 'Hourly' | 'Calendar',
            detail: string,
            dueIn: number
        }>
    } {
        let minHoursDifference = Infinity;
        let minMonthsDifference = Infinity;
        let nextServiceHours = null;
        let nextServiceDate = null;
        let componentHouryServiced = false;
        let componentCalendarServiced = false;
        let overallHoursStatus: ComponentStatus = 'OK';
        let overallCalendarStatus: ComponentStatus = 'OK';
        let upcomingMaintenances = [];

        if (component.maintenanceSchedule) {
            for (const maintenance of component.maintenanceSchedule) {
                for (const cycle of maintenance.cycle) {
                    if (cycle.type === 'Hourly') {

                        // Convert cycle.threshold to a number, removing any leading zeros
                        const maintenanceThreshold = typeof cycle.threshold === 'string' ? parseFloat(cycle.threshold) : cycle.threshold;
                        componentHouryServiced = true;

                        if (!isNaN(maintenanceThreshold)) {
                            const hoursSinceLastMaintenance = component.currentHours - maintenance.lastPerformed.hoursAtMaintenance;
                            const difference = maintenanceThreshold - hoursSinceLastMaintenance;

                            if (difference < minHoursDifference) {
                                minHoursDifference = parseFloat(difference.toFixed(1)); // Rounds to 1 decimal place and converts back to number
                                nextServiceHours = parseFloat((maintenance.lastPerformed.hoursAtMaintenance + maintenanceThreshold).toFixed(1)); // Same here
                                overallHoursStatus = difference < 0 ? 'Alert' : difference <= 10 ? 'Warning' : 'OK';
                            }
                            if (difference <= 10) {
                                upcomingMaintenances.push({
                                    type: 'Hours' as 'Hourly', // Explicitly assert the type
                                    detail: maintenance.description,
                                    dueIn: difference
                                });
                            }
                        }
                    }

                    if (maintenance.lastPerformed.expiryDate) {
                        const currentDate = new Date();

                        // Create a new Date object for nextMaintenanceDate calculation
                        const nextMaintenanceDate = new Date(maintenance.lastPerformed.expiryDate);
                        const monthsDifference = monthDiffWithDecimal(currentDate, nextMaintenanceDate);

                        if (monthsDifference < minMonthsDifference) {
                            minMonthsDifference = monthsDifference;
                            nextServiceDate = nextMaintenanceDate;
                            componentCalendarServiced = true;
                            overallCalendarStatus = monthsDifference < 0 ? 'Alert' : monthsDifference <= 6 ? 'Warning' : 'OK';

                        }
                        if (monthsDifference <= 6) {
                            upcomingMaintenances.push({
                                type: 'Months' as 'Calendar', // Explicitly set as 'Calendar'
                                detail: maintenance.description,
                                dueIn: monthsDifference
                            });
                        }
                    }
                }
            }
        }

        // If there's no maintenance schedule or current hours, return null values
        if (minHoursDifference === Infinity && minMonthsDifference === Infinity) {
            return {
                minHoursDifference: null,
                minMonthsDifference: null,
                nextServiceHours: null,
                nextServiceDate: nextServiceDate,
                componentHourlyServiced: false,
                componentCalendarServiced: false,
                overallHoursStatus: 'Alert',
                overallCalendarStatus: 'Alert',
                upcomingMaintenance: []
            };
        }

        let uniqueMaintenanceSet = new Set();
        let filteredUpcomingMaintenances = [];

        for (const maintenance of upcomingMaintenances) {
            const identifier = maintenance.type + ':' + maintenance.detail;
            if (!uniqueMaintenanceSet.has(identifier)) {
                uniqueMaintenanceSet.add(identifier);
                filteredUpcomingMaintenances.push(maintenance);
            }
        }

        return {
            minHoursDifference: minHoursDifference,
            minMonthsDifference: minMonthsDifference,
            nextServiceHours: nextServiceHours,
            nextServiceDate: nextServiceDate,
            componentHourlyServiced: componentHouryServiced,
            componentCalendarServiced: componentCalendarServiced,
            overallHoursStatus: overallHoursStatus,
            overallCalendarStatus: overallCalendarStatus,
            upcomingMaintenance: filteredUpcomingMaintenances
        };
    }

    const componentsWithMaintenanceDetails = useMemo(() => {
        // Flatten the components array from all aircrafts
        const allComponents = aircraftData.aircraft.flatMap(aircraft => aircraft.components || []);

        return allComponents.map(component => {
            return {
                ...component,
                maintenanceDetails: getNextMaintenanceDetails(component)
            };
        });
    }, [aircraftData]);

    let aircraftWithDetailedComponents = {...aircraftData.aircraft[0]};
    if (aircraftData.aircraft.length > 0) {
        aircraftWithDetailedComponents.components = componentsWithMaintenanceDetails;
    }
    const updateAircraft = async (aircraftToUpdate: Aircraft) => {
        try {
            await apiClient.post('/v1/editAircraft', aircraftToUpdate, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError("Unable to update aircraft.");
            }
        }
    };
    const handleAddComponentClick = () => {
        setSelectedComponent(undefined);
        setCurrentUI(UIState.AddComponentForm);
    };
    const handleIssuesClick = () => {
        setCurrentUI(UIState.AircraftIssues);
    };
    const handleEditComponentClick = (componentUuid: string) => {
        const componentToEdit = aircraftData.aircraft[0].components?.find(c => c.uuid === componentUuid);
        setSelectedComponent(componentToEdit);
        setCurrentUI(UIState.AddComponentForm);
        console.log(componentToEdit)
    };
    const handleAddComponentSubmitClick = (updatedAircraft: Aircraft) => {
        // Call updateAircraft to send the updated data to the server
        updateAircraft(updatedAircraft);

        // Update the local state
        setAircraftData(prevData => ({
            ...prevData,
            aircraft: prevData.aircraft.map(ac => ac.uuid === updatedAircraft.uuid ? updatedAircraft : ac)
        }));

        setCurrentUI(UIState.AircraftDetails);
    };
    const handleAircraftIssueSectionClick = (aircraftToShow: Aircraft) => {

    }

    if (aircraftData.aircraft.length === 0) {
        return (<div className="flex justify-center items-center py-10">
            <span className="text-gray-500">Loading aircraft...</span>
        </div>)
    }

    if (error) {
        return <div>Error {error}</div>
    }

    return (
        <div>
            <div>
                {currentUI === UIState.AircraftDetails && aircraftData.aircraft.length > 0 && (
                    <AircraftDetails
                        aircraft={aircraftWithDetailedComponents}
                        onAddComponent={handleAddComponentClick}
                        onEditComponent={handleEditComponentClick}
                        onIssuesClick={handleIssuesClick}
                    />
                )}
                {currentUI === UIState.AddComponentForm && (
                    <AircraftComponentSection aircraft={aircraftData.aircraft[0]}
                                              onBack={() => setCurrentUI(UIState.AircraftDetails)}
                                              onSubmit={handleAddComponentSubmitClick}
                                              initialComponent={selectedComponent}/>
                )}
                {currentUI === UIState.AircraftIssues && (
                    <AircraftIssueSection aircraft={aircraftWithDetailedComponents}/>
                )}
            </div>
        </div>
    );
};

export default AircraftManagementContainer;
