import React from "react";
import TechnicalSpecificationsSection from "./TechnicalSpecificationsSection";
import OfferSection from "./OfferSection";
import ContactFormSection from "./ContactFormSection";
import FeatureSection from "./FeatureSection";
import FAQSection from "./FAQSection"
import { useTranslation } from 'react-i18next';

const LandingScreen: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="bg-white">
            <FeatureSection/>
            <TechnicalSpecificationsSection/>
            <OfferSection/>
            <ContactFormSection/>
            <FAQSection/>
            <footer className="bg-white">
                <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center space-x-6 md:order-2">
                            <a href="/privacy-policy" className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                                {t('privacyPolicy')}
                            </a>
                    </div>
                    <div className="mt-8 md:order-1 md:mt-0">
                        <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; 2023 MG Invest sp. z o.o. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingScreen;
