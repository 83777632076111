// MaintenanceHistoryModal.tsx
import React, { useState, useEffect } from 'react';
import { MaintenanceHistoryRecord } from "../../../../types";

interface MaintenanceHistoryModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (record: MaintenanceHistoryRecord) => void;
}

const MaintenanceHistoryModal: React.FC<MaintenanceHistoryModalProps> = ({
                                                                             open,
                                                                             onClose,
                                                                             onSave
                                                                         }) => {
    const [record, setRecord] = useState<MaintenanceHistoryRecord>({
        date: '',
        expiryDate: null,
        hoursAtMaintenance: 0,
        confirmationDocument: '',
        remarks: ''
    });

    useEffect(() => {
        if (!open) {
            setRecord({
                date: '',
                expiryDate: null,
                hoursAtMaintenance: 0,
                confirmationDocument: '',
                remarks: ''
            });
        }
    }, [open]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setRecord(prev => ({
            ...prev,
            [name]: name === 'hoursAtMaintenance' ? parseFloat(value) : value
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave(record);
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-96 overflow-y-auto max-h-full">
                <h2 className="text-lg font-semibold mb-4">Add Maintenance History</h2>

                {/* Date */}
                <div className="mb-4">
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                        Date
                    </label>
                    <input
                        type="date"
                        name="date"
                        id="date"
                        value={record.date}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                       focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Expiry Date */}
                <div className="mb-4">
                    <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
                        Expiry Date
                    </label>
                    <input
                        type="date"
                        name="expiryDate"
                        id="expiryDate"
                        value={record.expiryDate || ''}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                       focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Hours at Maintenance */}
                <div className="mb-4">
                    <label htmlFor="hoursAtMaintenance" className="block text-sm font-medium text-gray-700">
                        Hours at Maintenance
                    </label>
                    <input
                        type="number"
                        name="hoursAtMaintenance"
                        id="hoursAtMaintenance"
                        value={record.hoursAtMaintenance}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                       focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Confirmation Document */}
                <div className="mb-4">
                    <label htmlFor="confirmationDocument" className="block text-sm font-medium text-gray-700">
                        Confirmation Document
                    </label>
                    <input
                        type="text"
                        name="confirmationDocument"
                        id="confirmationDocument"
                        value={record.confirmationDocument}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                       focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Remarks */}
                <div className="mb-4">
                    <label htmlFor="remarks" className="block text-sm font-medium text-gray-700">
                        Remarks
                    </label>
                    <textarea
                        name="remarks"
                        id="remarks"
                        value={record.remarks}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                       focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    ></textarea>
                </div>

                {/* Form Actions */}
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onClose}
                        className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MaintenanceHistoryModal;
