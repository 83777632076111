import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://mgaero.eu:8443/api',
    // other configurations
} as any);

// Response Interceptor
apiClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 403) {
            // Handle the redirect to login logic here
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default apiClient;
