import React from "react";
import {CheckIcon} from '@heroicons/react/20/solid'
import {useTranslation, Trans} from "react-i18next";

const OfferSection: React.FC = () => {
    const {t} = useTranslation();
    const tiers = [
        {
            name: t('tierHobbyName'),
            id: 'tier-hobby',
            href: '#',
            priceHourly: '690 PLN',
            description: t('tierHobbyDesc'),
            features: [
                t('tierHobbyFeature1'),
                t('tierHobbyFeature2'),
                t('tierHobbyFeature3'),
                t('tierHobbyFeature4'),
            ],
        },
        {
            name: t('tierProName'),
            id: 'tier-pro',
            href: '#',
            priceHourly: '590 PLN',
            description: t('tierProDesc'),
            features: [
                t('tierProFeature1'),
                t('tierProFeature2'),
                t('tierProFeature3'),
                t('tierProFeature4'),
                t('tierProFeature5'),
            ],
        }
    ];
    return (
        <div id="offer" className="isolate overflow-hidden bg-gray-900">
            <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
                <div className="mx-auto max-w-4xl">
                    <h2 className="text-base font-semibold leading-7 text-indigo-400">{t('rentalPricingTitle')}</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        <Trans
                            i18nKey="rentalPricingSubtitle"
                            components={{break: <br className="hidden sm:inline lg:hidden"/>}}
                        />
                    </p>
                </div>
                <div className="relative mt-6">
                    <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
                        {t('rentalPricingDescription')}
                    </p>
                    <svg
                        viewBox="0 0 1208 1024"
                        className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
                    >
                        <ellipse cx={604} cy={512} fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx={604} ry={512}/>
                        <defs>
                            <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                                <stop stopColor="#7775D6"/>
                                <stop offset={1} stopColor="#E935C1"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="flow-root bg-white pb-24 sm:pb-32">
                <div className="-mt-80">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
                            {tiers.map((tier) => (
                                <div
                                    key={tier.id}
                                    className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                                >
                                    <div>
                                        <h3 id={tier.id} className="text-base font-semibold leading-7 text-indigo-600">
                                            {t(tier.name)}
                                        </h3>
                                        <div className="mt-4 flex items-baseline gap-x-2">
                                            <span
                                                className="text-5xl font-bold tracking-tight text-gray-900">{tier.priceHourly}</span>
                                            <span
                                                className="text-base font-semibold leading-7 text-gray-600">/ MTH</span>
                                            <span
                                                className="text-base text-xs text-gray-400">{t('tierPriceNet')}</span>
                                        </div>
                                        <p className="mt-6 text-base leading-7 text-gray-600">{t(tier.description)}</p>
                                        <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                                            {tier.features.map((featureKey) => (
                                                <li key={featureKey} className="flex gap-x-3">
                                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600"
                                                               aria-hidden="true"/>
                                                    {t(featureKey)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OfferSection;