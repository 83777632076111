import OE_KMT_mountains from "../../media/OE_KMT_mountains.jpeg";
import React from "react";
import {useTranslation} from "react-i18next";

type Feature = {
    name: string;
    description: string;
};

const TechnicalSpecificationsSection: React.FC = () => {
    const { t } = useTranslation();
    const features: Feature[] = [
        {
            name: t('featureEngine'),
            description: t('featureEngineDesc'),
        },
        {
            name: t('featureSpeed'),
            description: t('featureSpeedDesc'),
        },
        {
            name: t('featureVersatility'),
            description: t('featureVersatilityDesc'),
        },
    ];

    return (
        <div id="plane" className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('cessnaTitle')}</h2>
                    <p className="mt-4 text-gray-500">
                        {t('cessnaDesc')}
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 sm:gap-y-16 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
                    <img
                        src={OE_KMT_mountains}
                        alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        className="rounded-lg bg-gray-100"
                    />

                </div>
            </div>
        </div>

    );

};

export default TechnicalSpecificationsSection;