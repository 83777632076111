import React, {ReactElement} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

interface ProtectedRouteProps {
    element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    const isAuthenticated = Boolean(sessionStorage.getItem('token'));
    const location = useLocation();

    if (isAuthenticated) {
        return element;
    } else {
        return <Navigate to="/login" state={{from: location}}/>;
    }
};

export default ProtectedRoute;
