// src/components/Dashboard/AircraftComponents/AddEditViewForms/AircraftComponentSection.tsx

import React, {useState, useEffect} from 'react';
import MaintenanceScheduleForm from "./MaintenanceScheduleForm";
import AdsbForm from "./AdsbForm";
import {v4 as uuidv4} from "uuid";
import MaintenanceHistoryModal from "./MaintenanceHistoryModal";
import {ADSBHistoryModal} from "./ADSBHistoryModal";
import {
    MaintenanceSchedule,
    ADSBCompliance,
    ADSBComplianceHistoryRecord,
    InstalledComponent,
    Aircraft
} from "../../../../types";
import {classNames, toShortDateFormat} from "../../../../tools/utils";

interface AddComponentFormProps {
    aircraft: Aircraft;
    onBack: () => void;
    onSubmit: (updatedAircraft: Aircraft) => void;
    initialComponent?: InstalledComponent; // Optional prop
}

interface MaintenanceHistoryRecord {
    date: string;
    expiryDate: string | null;
    hoursAtMaintenance: number;
    confirmationDocument: string;
    remarks: string;
}

const AircraftComponentSection: React.FC<AddComponentFormProps> = ({
                                                                       aircraft,
                                                                       onBack,
                                                                       onSubmit,
                                                                       initialComponent
                                                                   }) => {
    const [showAddScheduleForm, setShowAddScheduleForm] = useState(false);
    const [showAddAdsbForm, setShowAddAdsbForm] = useState(false);
    const [selectedMaintenanceSchedule, setSelectedMaintenanceSchedule] = useState<MaintenanceSchedule | null>(null);
    const [selectedAdsb, setSelectedAdsb] = useState<ADSBCompliance | null>(null);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [selectedScheduleForHistory, setSelectedScheduleForHistory] = useState<MaintenanceSchedule | null>(null);
    const [showADSBHistoryModal, setShowADSBHistoryModal] = useState(false);
    const [selectedADSBForHistory, setSelectedADSBForHistory] = useState<ADSBCompliance | null>(null);
    const [formValues, setFormValues] = useState<InstalledComponent>({
        uuid: '',
        title: '',
        partNumber: '',
        serialNumber: '',
        currentHours: 0,
        maintenanceSchedule: [],
        maintenanceHistory: [],
        adsbCompliance: []
    });

    useEffect(() => {
        if (initialComponent) {
            setFormValues({
                uuid: initialComponent.uuid || '',
                title: initialComponent.title || '',
                partNumber: initialComponent.partNumber || '',
                serialNumber: initialComponent.serialNumber || '',
                currentHours: initialComponent.currentHours || 0,
                maintenanceSchedule: initialComponent.maintenanceSchedule || [],
                maintenanceHistory: initialComponent.maintenanceHistory || [],
                adsbCompliance: initialComponent.adsbCompliance || []
            });
        }
    }, [initialComponent]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        const floatValueFields = [
            "currentHours"
        ];

        // Check if the field name is one that should be a float, and convert the value if so.
        const isFloatValueField = floatValueFields.includes(name);

        let newValue: string | number = value;
        if (isFloatValueField && value !== '') {
            newValue = parseFloat(value);
        }

        // Update state with the new value
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: newValue
        }));
    };

    /**
     * Handler to add or update a maintenance schedule.
     * @param newSchedule The maintenance schedule to add or update.
     */
    const handleAddSchedule = (newSchedule: MaintenanceSchedule) => {
        // Check if the schedule already exists based on UUID
        const scheduleIndex = formValues.maintenanceSchedule.findIndex(schedule => schedule.uuid === newSchedule.uuid);
        let updatedMaintenanceSchedules;
        if (scheduleIndex >= 0) {
            // Schedule exists, update it
            updatedMaintenanceSchedules = formValues.maintenanceSchedule.map((schedule, index) =>
                index === scheduleIndex ? newSchedule : schedule
            );
        } else {
            // Schedule doesn't exist, add it to the array
            updatedMaintenanceSchedules = [...formValues.maintenanceSchedule, newSchedule];
        }

        const updatedComponent = {
            ...formValues,
            maintenanceSchedule: updatedMaintenanceSchedules
        };

        // Update the formValues state with the updated component
        setFormValues(updatedComponent);
        setShowAddScheduleForm(false);
    };

    /**
     * Handler to add or update an ADSB compliance record.
     * @param newAdsb The ADSB compliance record to add or update.
     */
    const handleAddAdsb = (newAdsb: ADSBCompliance) => {
        // Check if the ADSB already exists based on UUID
        const adsbIndex = formValues.adsbCompliance.findIndex(adsb => adsb.uuid === newAdsb.uuid);

        let updatedAdsbs;
        if (adsbIndex >= 0) {
            // ADSB exists, update it
            updatedAdsbs = formValues.adsbCompliance.map((adsb, index) =>
                index === adsbIndex ? newAdsb : adsb
            );
        } else {
            // ADSB doesn't exist, add it to the array
            updatedAdsbs = [...formValues.adsbCompliance, newAdsb];
        }

        const updatedComponent = {
            ...formValues,
            adsbCompliance: updatedAdsbs
        };

        // Update the formValues state with the updated component
        setFormValues(updatedComponent);
        setShowAddAdsbForm(false);
    };

    /**
     * Handler to initiate editing of a maintenance schedule.
     * @param schedule The maintenance schedule to edit.
     */
    const handleEditSchedule = (schedule: MaintenanceSchedule) => {
        setSelectedMaintenanceSchedule(schedule);
        setShowAddScheduleForm(true);
    };

    /**
     * Handler to save a maintenance history record.
     * @param updatedHistoryRecord The history record to save.
     */
    const handleSaveHistory = (updatedHistoryRecord: MaintenanceHistoryRecord) => {
        if (selectedScheduleForHistory) {
            const updatedSchedules = formValues.maintenanceSchedule.map((schedule) => {
                if (schedule.uuid === selectedScheduleForHistory.uuid) {
                    // Ensure history is an array before trying to spread it
                    const historyArray = Array.isArray(schedule.history) ? schedule.history : [];
                    // Update the selected schedule's lastPerformed and history
                    return {
                        ...schedule,
                        lastPerformed: {...updatedHistoryRecord},
                        history: [...historyArray, updatedHistoryRecord],
                    };
                }
                return schedule; // Leave other schedules unchanged
            });

            // Update state with the new schedules array
            setFormValues((prevValues) => ({
                ...prevValues,
                maintenanceSchedule: updatedSchedules,
            }));

            // Close the modal and clear the selected schedule for history
            setShowHistoryModal(false);
            setSelectedScheduleForHistory(null);
        }
    };

    /**
     * Handler to initiate editing of an ADSB compliance record.
     * @param adsb The ADSB compliance record to edit.
     */
    const handleEditAdsb = (adsb: ADSBCompliance) => {
        setSelectedAdsb(adsb);
        setShowAddAdsbForm(true);
    };

    /**
     * Handler to initiate editing of an ADSB compliance history record.
     * @param adsb The ADSB compliance record to edit history for.
     */
    const handleEditADSBHistory = (adsb: ADSBCompliance) => {
        setSelectedADSBForHistory(adsb);
        setShowADSBHistoryModal(true);
    };

    /**
     * Handler to save an ADSB compliance history record.
     * @param updatedHistoryRecord The ADSB compliance history record to save.
     */
    const handleSaveADSBHistory = (updatedHistoryRecord: ADSBComplianceHistoryRecord) => {
        // Ensure there's a selected ADSB compliance object to update
        if (selectedADSBForHistory) {
            const updatedAdsbCompliance = formValues.adsbCompliance.map(adsb => {
                if (adsb.uuid === selectedADSBForHistory.uuid) {
                    // Found the ADSB compliance object to update
                    return {
                        ...adsb,
                        complianceHistory: [...adsb.complianceHistory, updatedHistoryRecord]
                    };
                }
                return adsb; // Leave other ADSB compliance objects unchanged
            });

            // Update the component's state with the updated ADSB compliance list
            setFormValues(prevValues => ({
                ...prevValues,
                adsbCompliance: updatedAdsbCompliance
            }));

            // Reset state related to ADSB history modal
            setShowADSBHistoryModal(false);
            setSelectedADSBForHistory(null);
        }
    };

    /**
     * Handler for form submission to save the component.
     */
    const handleSubmit = () => {
        let updatedComponents;

        if (!formValues.uuid) {
            // Adding a new component
            formValues.uuid = uuidv4(); // Generate a new UUID for the new component
            updatedComponents = [...(aircraft.components || []), formValues];
        } else {
            // Updating an existing component
            updatedComponents = (aircraft.components || []).map(component =>
                component.uuid === formValues.uuid ? formValues : component
            );
        }

        // Update the aircraft object with the new or updated components
        const updatedAircraft = {...aircraft, components: updatedComponents};

        onSubmit(updatedAircraft); // Pass the updated aircraft object to the onSubmit function
    };

    /**
     * Formats an ISO date string to a short date format.
     * @param isoString The ISO date string.
     * @returns The formatted date string.
     */
    const toShortDateFormat = (isoString: string) => {
        const date = new Date(isoString);
        return date.toLocaleDateString('pl-PL');
    };

    /**
     * Determines the CSS class for an ADSB compliance row based on its status.
     * @param adsb The ADSB compliance record.
     * @returns A string representing the CSS class.
     */
    function getAdsbCellStyle(adsb: ADSBCompliance) {
        if (adsb.repeatable && adsb.single) {
            return 'bg-red-50'; // Apply a special styling for critical issues
        }

        // Sort complianceHistory by date in descending order to get the most recent item first
        const mostRecentHistory = adsb.complianceHistory
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0] || null;

        // Check if the most recent compliance history item exists and is confirmed
        if (mostRecentHistory && !mostRecentHistory.confirmed) {
            return 'bg-red-50'; // Apply a special styling if the most recent history item is not confirmed
        }

        // If the compliance is not critical and the most recent history item (if exists) is confirmed, return default style
        return ''; // Default style or class
    }

    /**
     * Determines the CSS class for a maintenance schedule row based on its next service date and hours.
     * @param schedule The maintenance schedule to evaluate.
     * @returns A string representing the CSS class.
     */
    function getScheduleCellStyle(schedule: MaintenanceSchedule) {
        const {nextServiceDate, nextServiceHours} = calculateNextService(schedule);

        const currentDate = new Date();
        const currentHours = formValues.currentHours; // Assumed to be part of InstalledComponent

        let className = '';

        if (nextServiceDate) {
            const serviceDate = new Date(nextServiceDate);
            if (serviceDate < currentDate) {
                className = 'bg-red-50'; // Overdue
            } else {
                const sixMonthsLater = new Date();
                sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
                if (serviceDate < sixMonthsLater) {
                    className = 'bg-yellow-50'; // Approaching
                }
            }
        }

        if (nextServiceHours !== null && nextServiceHours < currentHours) {
            className = 'bg-red-50'; // Overdue
        }

        return className;
    }

    /**
     * Calculates the next service date and hours based on the maintenance schedule cycles.
     * @param schedule The maintenance schedule.
     * @returns An object containing nextServiceDate and nextServiceHours.
     */
    const calculateNextService = (schedule: MaintenanceSchedule): {
        nextServiceDate: string | null,
        nextServiceHours: number | null
    } => {
        let earliestDate: Date | null = null;
        let earliestHours: number | null = null;

        schedule.cycle.forEach(cycle => {
            const threshold = parseInt(String(cycle.threshold), 10);
            if (cycle.type.toLowerCase() === 'monthly') {
                const lastDate = new Date(schedule.lastPerformed.date);
                const serviceDate = new Date(lastDate);
                serviceDate.setMonth(serviceDate.getMonth() + threshold);
                if (!earliestDate || serviceDate < earliestDate) {
                    earliestDate = serviceDate;
                }
            } else if (cycle.type.toLowerCase() === 'hourly') {
                const serviceHours = schedule.lastPerformed.hoursAtMaintenance + threshold;
                if (!earliestHours || serviceHours < earliestHours) {
                    earliestHours = serviceHours;
                }
            }
            // Handle other types like 'Special' if needed
        });

        return {
            nextServiceDate: earliestDate ? (earliestDate as Date).toISOString().split('T')[0] : null,
            nextServiceHours: earliestHours || null
        };
    };

    /**
     * Utility function to join class names.
     * @param classes Array of class names.
     * @returns A single string of class names.
     */
    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ');
    }

    // Determine which form to display
    const isScheduleFormVisible = showAddScheduleForm && !showAddAdsbForm;
    const isAdsbFormVisible = !showAddScheduleForm && showAddAdsbForm;

    return (
        <div>
            <main>
                {/* Display Maintenance Schedule Form */}
                {isScheduleFormVisible && (
                    <MaintenanceScheduleForm
                        onSubmit={handleAddSchedule}
                        onCancel={() => {
                            setShowAddScheduleForm(false);
                            setSelectedMaintenanceSchedule(null);
                        }}
                        maintenanceSchedule={selectedMaintenanceSchedule}
                    />
                )}

                {/* Display ADSB Form */}
                {isAdsbFormVisible && (
                    <AdsbForm
                        onSubmit={handleAddAdsb}
                        onCancel={() => {
                            setShowAddAdsbForm(false);
                            setSelectedAdsb(null);
                        }}
                        adsb={selectedAdsb}
                    />
                )}

                {/* Display Main Forms and Tables */}
                {!isScheduleFormVisible && !isAdsbFormVisible && (
                    <div className="mt-8 px-4 sm:px-6 lg:px-8">
                        <div className="space-y-10 divide-y divide-gray-900/10">
                            {/* General Information Form */}
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                                    <div className="px-4 sm:px-0">
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">General</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">
                                            Part Title, Part Number, Serial Number, Current Time
                                        </p>
                                    </div>
                                    <div
                                        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                                        <div className="px-4 py-4 sm:p-8">
                                            {/* Component Title and Part Number */}
                                            <div
                                                className="grid max-w-2xl py-2 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="title"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Component Title
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            id="title"
                                                            autoComplete="componentTitle"
                                                            value={formValues.title}
                                                            onChange={handleChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="partNumber"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Part Number
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="partNumber"
                                                            id="partNumber"
                                                            autoComplete="componentPartNumber"
                                                            value={formValues.partNumber}
                                                            onChange={handleChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Serial Number and Current Hours */}
                                            <div
                                                className="grid max-w-2xl py-2 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="serialNumber"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Serial Number
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="serialNumber"
                                                            id="serialNumber"
                                                            autoComplete="componentSerialNumber"
                                                            value={formValues.serialNumber}
                                                            onChange={handleChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="currentHours"
                                                           className="block text-sm font-medium leading-6 text-gray-900">
                                                        Current Hours
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="number"
                                                            name="currentHours"
                                                            id="currentHours"
                                                            autoComplete="componentCurrentHours"
                                                            value={formValues.currentHours}
                                                            onChange={handleChange}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Form Actions */}
                                <div className="flex items-center justify-end gap-x-6 gap-y-8 p-8">
                                    <button
                                        type="button"
                                        onClick={onBack}
                                        className="text-sm font-semibold leading-6 text-gray-900">
                                        Back
                                    </button>
                                    {sessionStorage.getItem('user_role') === 'admin' && (
                                        <button
                                            type="submit"
                                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </form>
                            {/* Maintenance Schedule Section */}
                            <div className="px-4 py-6 sm:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">Maintenance
                                            Schedule for {formValues.title} {formValues.partNumber}</h1>
                                        <p className="mt-2 text-sm text-gray-700">
                                            S/N: {formValues.serialNumber}
                                        </p>
                                    </div>
                                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                        {sessionStorage.getItem('user_role') === 'admin' && (
                                            <button
                                                type="button"
                                                onClick={() => setShowAddScheduleForm(true)}
                                                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Add Item
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-8 flow-root">
                                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle">
                                            {formValues.maintenanceSchedule && formValues.maintenanceSchedule.length > 0 ? (
                                                <table className="min-w-full border-separate border-spacing-0">
                                                    <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                                            Title
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Cycle
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Performed
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Next Service
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Confirmation
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Remarks
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                                            <span className="sr-only">Add History</span>
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                    {formValues.maintenanceSchedule.map((schedule, scheduleIdx) => {
                                                        const {
                                                            nextServiceDate,
                                                            nextServiceHours
                                                        } = calculateNextService(schedule);
                                                        return (
                                                            <tr key={schedule.uuid}
                                                                className={getScheduleCellStyle(schedule)}>
                                                                {/* Title */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'py-2 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                                                )}>
                                                                    {schedule.description}
                                                                </td>
                                                                {/* Cycle */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'hidden px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                                                )}>
                                                                    {schedule.cycle.map((item, idx) => (
                                                                        <span key={idx}>
                                                                                {item.type}: {item.threshold}
                                                                            {idx < schedule.cycle.length - 1 && <br/>}
                                                                            </span>
                                                                    ))}
                                                                </td>
                                                                {/* Performed */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'py-2 px-3 text-xs text-gray-500'
                                                                )}>
                                                                    <div>Date: {schedule.lastPerformed.date ? toShortDateFormat(schedule.lastPerformed.date) : 'N/A'}</div>
                                                                    <div>Hours: {schedule.lastPerformed.hoursAtMaintenance || 'N/A'}</div>
                                                                </td>
                                                                {/* Next Service */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'py-2 px-3 text-xs text-gray-500'
                                                                )}>
                                                                    {nextServiceDate || nextServiceHours !== null ? (
                                                                        <>
                                                                            {nextServiceDate &&
                                                                                <div>Date: {toShortDateFormat(nextServiceDate)}</div>}
                                                                            {nextServiceHours !== null &&
                                                                                <div>Hours: {nextServiceHours}</div>}
                                                                        </>
                                                                    ) : (
                                                                        <span>N/A</span>
                                                                    )}
                                                                </td>
                                                                {/* Confirmation */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'py-2 px-3 text-xs text-gray-500'
                                                                )}>
                                                                    {schedule.lastPerformed.confirmationDocument || 'N/A'}
                                                                </td>
                                                                {/* Remarks */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'hidden px-3 py-2 text-xs text-gray-500 sm:table-cell'
                                                                )}>
                                                                    {schedule.lastPerformed.remarks || 'None'}
                                                                </td>
                                                                {/* Add History Button */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'relative whitespace-nowrap py-2 pr-4 pl-3 text-right text-xs font-medium sm:pr-8 lg:pr-8'
                                                                )}>
                                                                    {sessionStorage.getItem('user_role') === 'admin' && (
                                                                        <button
                                                                            onClick={() => {
                                                                                setSelectedScheduleForHistory(schedule);
                                                                                setShowHistoryModal(true);
                                                                            }}
                                                                            className="text-indigo-600 hover:text-indigo-900">
                                                                            Add History<span
                                                                            className="sr-only">, {schedule.uuid}</span>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                                {/* Edit Button */}
                                                                <td className={classNames(
                                                                    scheduleIdx !== formValues.maintenanceSchedule.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'relative whitespace-nowrap py-2 pr-4 pl-3 text-right text-xs font-medium sm:pr-8 lg:pr-8'
                                                                )}>
                                                                    {sessionStorage.getItem('user_role') === 'admin' && (
                                                                        <button
                                                                            onClick={() => handleEditSchedule(schedule)}
                                                                            className="text-indigo-600 hover:text-indigo-900">
                                                                            Edit<span
                                                                            className="sr-only">, {schedule.uuid}</span>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    {/* Maintenance History Modal */}
                                                    {showHistoryModal && selectedScheduleForHistory && (
                                                        <MaintenanceHistoryModal
                                                            open={showHistoryModal}
                                                            onClose={() => setShowHistoryModal(false)}
                                                            onSave={handleSaveHistory}
                                                        />
                                                    )}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                // Placeholder when there are no maintenance schedules
                                                <div className="text-center">
                                                    <h3 className="mt-1 text-sm font-semibold text-gray-900">No
                                                        maintenance schedules</h3>
                                                    <p className="mt-1 mb-2 text-sm text-gray-500">Get started by adding
                                                        a new maintenance schedule.</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ADSB Compliance Section */}
                            <div className="px-4 py-6 sm:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">AD / SB
                                            Compliance for {formValues.title} {formValues.partNumber}</h1>
                                        <p className="mt-2 text-sm text-gray-700">
                                            S/N: {formValues.serialNumber}
                                        </p>
                                    </div>
                                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                        {sessionStorage.getItem('user_role') === 'admin' && (
                                            <button
                                                type="button"
                                                onClick={() => setShowAddAdsbForm(true)}
                                                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Add AD/SB
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-8 flow-root">
                                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle">
                                            {formValues.adsbCompliance && formValues.adsbCompliance.length > 0 ? (
                                                <table className="min-w-full border-separate border-spacing-0">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                                            AD
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                                            SB
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Subject
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Method
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Repeatable / Single
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                                            Complied
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                                            <span className="sr-only">Add History</span>
                                                        </th>
                                                        <th scope="col"
                                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {formValues.adsbCompliance.map((adsb, adsbIdx) => {
                                                        // Find the most recent history record
                                                        const mostRecentHistory = adsb.complianceHistory
                                                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0] || null;

                                                        return (
                                                            <tr key={adsb.uuid} className={getAdsbCellStyle(adsb)}>
                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                                                )}>
                                                                    {adsb.airworthinessDirectiveId}
                                                                </td>
                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                                                )}>
                                                                    {adsb.serviceBulletinId}
                                                                </td>
                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'hidden px-3 py-4 text-xs text-gray-500 sm:table-cell'
                                                                )}>{adsb.subject}</td>
                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'hidden px-3 py-4 text-xs text-gray-500 sm:table-cell'
                                                                )}>{adsb.method}</td>

                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'hidden px-3 py-4 text-xs text-gray-500 sm:table-cell'
                                                                )}>
                                                                    {adsb.repeatable && 'Repeatable'}
                                                                    {adsb.repeatable && adsb.single && ' & '}
                                                                    {adsb.single && 'Single'}
                                                                    {!adsb.repeatable && !adsb.single && 'N/A'}
                                                                </td>

                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'px-3 py-4 text-xs text-gray-500 sm:table-cell'
                                                                )}>
                                                                    {mostRecentHistory ? (
                                                                        <React.Fragment>
                                                                                <span>
                                                                                    Date: {mostRecentHistory.date && toShortDateFormat(mostRecentHistory.date)},
                                                                                    Flight Hours: {mostRecentHistory.flightHoursPerformed !== null && mostRecentHistory.flightHoursPerformed !== undefined ? mostRecentHistory.flightHoursPerformed : 'N/A'},
                                                                                    Motor Hours: {mostRecentHistory.motorHoursPerformed !== null && mostRecentHistory.motorHoursPerformed !== undefined ? mostRecentHistory.motorHoursPerformed : 'N/A'},
                                                                                    Comment: {mostRecentHistory.comment},
                                                                                    Details: {mostRecentHistory.details},
                                                                                    Confirmed: {mostRecentHistory.confirmed ? 'Yes' : 'No'}
                                                                                </span>
                                                                        </React.Fragment>
                                                                    ) : 'No history available'}
                                                                </td>
                                                                {/* Add History Button */}
                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-xs font-medium sm:pr-8 lg:pr-8'
                                                                )}>
                                                                    {sessionStorage.getItem('user_role') === 'admin' && (
                                                                        <button
                                                                            onClick={() => handleEditADSBHistory(adsb)}
                                                                            className="text-indigo-600 hover:text-indigo-900">
                                                                            Add History<span
                                                                            className="sr-only">, {adsb.uuid}</span>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                                {/* Edit Button */}
                                                                <td className={classNames(
                                                                    adsbIdx !== formValues.adsbCompliance.length - 1 ? 'border-b border-gray-200' : '',
                                                                    'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-xs font-medium sm:pr-8 lg:pr-8'
                                                                )}>
                                                                    {sessionStorage.getItem('user_role') === 'admin' && (
                                                                        <button
                                                                            onClick={() => handleEditAdsb(adsb)}
                                                                            className="text-indigo-600 hover:text-indigo-900">
                                                                            Edit<span
                                                                            className="sr-only">, {adsb.uuid}</span>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    {/* ADSB Compliance History Modal */}
                                                    {showADSBHistoryModal && selectedADSBForHistory && (
                                                        <ADSBHistoryModal
                                                            open={showADSBHistoryModal}
                                                            onClose={() => setShowADSBHistoryModal(false)}
                                                            onSave={handleSaveADSBHistory}
                                                        />
                                                    )}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className="text-center">
                                                    <h3 className="mt-1 text-sm font-semibold text-gray-900">No AD or
                                                        SB</h3>
                                                    <p className="mt-1 mb-2 text-sm text-gray-500">Get started by adding
                                                        a new Airworthiness Directive or Service Bulletin.</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
}
export default AircraftComponentSection;
